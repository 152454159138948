import React from "react";
import "./videoPlayHeader.scss";

const VideoPlayHeader = ({ headerTitle }) => {
  return (
    <div className="videoPlayHeader">
      <h2>{headerTitle}</h2>
    </div>
  );
};

export default VideoPlayHeader;
