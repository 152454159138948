import React, { useEffect, useState } from "react";
import axios from "axios";
import MadahiCard from "../../Components/madahiCard/MadahiCard";

import "./madahi.scss";
import useFetch from "../../request/useFetch";

const Madahi = () => {
  const [madahiData, setMadahiData] = useState([]);

  // useEffect(() => {
  //   axios
  //     .get("/api/madah")
  //     .then((response) => {
  //       const Data = response.data.filter((madah) => madah.published);

  //       setMadahiData(Data);
  //     })
  //     .catch((error) => {});
  // }, []);

  const { data, error, loading } = useFetch("/api/madah");

  useEffect(() => {
    setMadahiData(data.filter((d) => d.publishStatus == "published"));
  }, [data]);

  return (
    !error &&
    !loading && (
      <>
        <div className="madahi">
          {madahiData.map((madahi) => (
            <MadahiCard key={madahi._id} {...madahi} />
          ))}
        </div>
      </>
    )
  );
};

export default Madahi;
