import React from "react";
import "./courseSubscriptionCard.scss";

const CourseSubscriptionCard = ({ wallet, price, button }) => {
  return (
    <div className={`subscriptionCard ${wallet}`}>
      <div className="circle">
        <div className="overlay"></div>
        <div className="circle__text">
          <h2>اشتراک</h2>
          {/* <p>{months}900000000</p> */}
        </div>
      </div>
      <p className="text">{price}</p>
      <button className="btn">{button}</button>
    </div>
  );
};

export default CourseSubscriptionCard;
