import React, { useState } from "react";
import { useFormik } from "formik";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./commentForm.scss";

const CommentForm = ({
  setNewCommentBoxStatus,
  commentReplyTo,
  setCommentReplyTo,
  courseId,
}) => {
  const [responseData, setResponseData] = useState(null);
  const yourApiUrl = "http://127.0.0.1:9000/";
  const Token =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY1MWFjODMzMzY4NDk5MmI5MzhkNTUxNSIsImlzQWRtaW4iOnRydWUsImlhdCI6MTcwNjA4MjQ1MSwiZXhwIjoxNzA2NTE0NDUxfQ.d2GPc8loLyZtxvVW52zRdg-e2OY-_ykqloAIFrhKhuI";
  const axiosInstance = axios.create({
    baseURL: yourApiUrl,
    headers: {
      "Content-Type": "application/json", // Change content-type to application/json
      Authorization: Token ? `Bearer ${Token}` : "",
    },
  });

  const formik = useFormik({
    initialValues: {
      reviewTitle: "",
      reviewText: "",
      rating: "",
    },
    validate: (values) => {
      const errors = {};

      if (!values.reviewTitle) {
        errors.reviewTitle = "عنوان نمی‌تواند خالی باشد";
      }
      if (!values.reviewText) {
        errors.reviewText = "متن نظر نمی‌تواند خالی باشد";
      }
      if (!values.rating) {
        errors.rating = "فرمت را انتخاب نکرده اید";
      }
      return errors;
    },

    onSubmit: async (values) => {
      try {
        const jsonData = {
          reviewTitle: values.reviewTitle,
          reviewText: values.reviewText,
          rating: values.rating,
        };

        const response = await axiosInstance.post(
          `/api/courseReview/${courseId}`,
          jsonData
        );

        setResponseData(response.data);
        toast.success("کامنت با موفقیت ثبت شد!");
      } catch (error) {
        console.error(error.message);
        toast.error("مشکلی در ارسال درخواست به وجود آمده است!");
      }
    },
  });

  function newCommentCancel() {
    setNewCommentBoxStatus(false);
    setCommentReplyTo(undefined);
  }

  return (
    <div className="commentForm">
      {responseData && (
        <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      )}
      <div className="commentForm__head">
        <div className="commentForm__head--img">
          <img
            src="https://secure.gravatar.com/avatar/e192667f009f89ffbdb0cff5f1e84b40?s=96&d=mm&r=g"
            alt="userLogo"
          />
        </div>
        <div className="commentForm__head__detail">
          <div className="commentForm__head__detail--userName">
            <span>username</span>
          </div>

          {commentReplyTo ? (
            <div className="commentForm__head__detail--replyTo">
              <p>در پاسخ به</p>
              <span>{commentReplyTo}</span>
            </div>
          ) : (
            <div className="commentForm__head__detail--replyTo">
              <p>ایجاد نظر جدید</p>
            </div>
          )}
        </div>
      </div>
      <form action="#" id="newCommentForm" onSubmit={formik.handleSubmit}>
        <input
          className="commentForm__comment--input"
          type="text"
          name="reviewTitle"
          id="reviewTitle"
          placeholder="عنوان نظر"
          onChange={formik.handleChange}
          value={formik.values.reviewTitle}
        />
        <textarea
          className="commentForm__comment--input"
          name="reviewText"
          id="reviewText"
          cols="35"
          rows="6"
          onChange={formik.handleChange}
          value={formik.values.reviewText}
        ></textarea>
        <label htmlFor="rating">امتیاز:</label>
        <select
          name="rating"
          id="rating"
          onChange={formik.handleChange}
          value={formik.values.rating}
        >
          <option value="">انتخاب کنید</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
        </select>
        <div className="commentForm__btns">
          <button type="submit" className="commentForm__btns--submit">
            ثبت
          </button>
          <button
            className="commentForm__btns--cancel"
            onClick={newCommentCancel}
          >
            لغو
          </button>
        </div>
      </form>
    </div>
  );
};

export default CommentForm;
