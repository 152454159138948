import React, { useEffect, useState } from "react";
import axios from "axios";

import VideoCard from "../../Components/videoCard/VideoCard";
import "./video.scss";
import { publicRequest } from "../../request/request";
import useFetch from "../../request/useFetch";

const Video = () => {
  const [VideoData, setVideoData] = useState([]);

  // useEffect(async () => {
  //   await publicRequest.get("/api/videos").then((response) => {
  //     const videos = response.data.filter(
  //       (video) =>
  //         (video.published && video.subset === "other") ||
  //         video.subset === "sokhanrani"
  //     );
  //     setVideoData(videos);
  //   });
  // }, []);

  const { data, error, loading } = useFetch("/api/videos");

  useEffect(() => {
    const videos = data.filter(
      (video) =>
        (video.publishStatus == "published" && video.subset === "other") ||
        video.subset === "sokhanrani"
    );
    setVideoData(videos);
  }, [data]);

  console.log(data, "Data");

  return (
    !error &&
    !loading && (
      <div className="videoPage">
        {VideoData.map((video) => (
          <VideoCard key={video._id} {...video} />
        ))}
      </div>
    )
  );
};

export default Video;
