import axios from "axios";

const getCourses = async () => {
  const response = await axios.get("/api/course");
  return response.data;
};

const coursesService = {
  getCourses,
};

export default coursesService;
