import React from "react";
import "./podcastCard.scss";
import { toFarsiNumber } from "../../utils/utilityFunctions";
import { Link } from "react-router-dom";

const PodcastCard = ({ name, cover, _id, bio }) => {
  const decodedCover = decodeURIComponent(cover);
  const coverfile = `/api/${decodedCover}`;

  return (
    <div className="podcastCard">
      <div className="podcastCard__image">
        <Link to={`/podcastEpisode/${_id}`}>
          <img src={coverfile} alt={name} />
          <span className="podcastCard__button">Play</span>
        </Link>
      </div>
      <div className="podcastCard__title">
        <span>{name}</span>
      </div>
      <div className="podcastCard__detail">
        <span className="podcastCard__detail__date">
          <span>{bio.split(" ").slice(0, 15).join(" ")}</span>
          {/* <span>تیر</span> */}
          {/* <span>{toFarsiNumber(31)}</span>
        </span>
        <span className="podcastCard__detail__time">
          <span>{toFarsiNumber(24)}</span>
          <span> دقیقه</span> */}
        </span>
      </div>
    </div>
  );
};

export default PodcastCard;
