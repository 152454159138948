import React, { useEffect, useRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import Hls from "hls.js";
import { IoMdShare, IoMdEye, IoMdFlag } from "react-icons/io";

import CommentBox from "../../Components/commentBox/CommentBox";
import VideoPlayHeader from "../../Components/videoPlayHeader/VideoPlayHeader";

const VideoPlay = () => {
  const { id } = useParams();
  const location = useLocation();
  const { video, title, videoId, description } = location.state || {};

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const videoRef = useRef(null);
  const decodedVideo = decodeURIComponent(video);
  const videoFile = `/api/${decodedVideo}/index.m3u8`;

  useEffect(() => {
    const handleVideoError = (e) => {
      console.error("Error while loading the video:", e);
    };

    if (videoRef.current) {
      let hls;
      if (Hls.isSupported()) {
        hls = new Hls();
        hls.loadSource(videoFile);
        hls.attachMedia(videoRef.current);
      } else if (
        videoRef.current.canPlayType("application/vnd.apple.mpegurl")
      ) {
        videoRef.current.src = videoFile;
      }

      return () => {
        if (hls) {
          hls.destroy();
        }
      };
    }
  }, [videoFile]);

  return (
    <div className="post-single">
      <div className="videoPlay">
        <div className="videoPlay__header">
          <VideoPlayHeader headerTitle={title} />
        </div>
        <div className="videoPlay__container">
          <video
            className="videoPlay__container--player"
            ref={videoRef}
            controls
          />
          <div className="iframe__info">
            <div className="iframe__info__right">
              <h1>{title}</h1>
              <p>{description}</p>
            </div>
            <div className="iframe__info__left">
              <p>{/* <span>۲,۲۱۴ بازدید کل</span> */}</p>
              <p>
                <IoMdShare />
              </p>
            </div>
          </div>
          <hr />
          <div className="iframe__description">
            <p> گزارش تخلف</p>
            <IoMdFlag size={24} style={{ marginTop: "-0.3rem" }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoPlay;
