import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./home.scss";
import { publicRequest } from "../../request/request";

import ChannelSlider from "../../Components/channelSlider/ChannelSlider";
import BannerSlider from "../../Components/bannerSlider/BannerSlider";
import QuranPlayer from "../../Components/quranPlayer/QuranPlayer";
import Character from "../../Components/character/Character";
import CourseCard from "../../Components/course/CourseCard";
import { BsArrowLeft, BsCameraReels } from "react-icons/bs";
import VideoCard from "../../Components/video/VideoCard";
import Podcast from "../../Components/podcast/Podcast";
import Footer from "../../Components/footer/Footer";
import Quran from "../../assets/quran/Quran.png";
import axios from "axios";

const Home = () => {
  const [channelSelected, setChannelSelected] = useState("tv");
  const [videoData, setVideoData] = useState([]);
  const [podcastData, setPodcastData] = useState([]);
  const [courseData, setCourseData] = useState([]);
  const [visibleCourses, setVisibleCourses] = useState(3);

  useEffect(() => {
    // axios.get("/api/course").then((response) => {
    //   setCourseData(response.data);
    // });
    const getCourse = async () => {
      try {
        const res = await publicRequest.get("/api/course");
        console.log(res.data, "course in home");
        setCourseData(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    const getVideos = async () => {
      try {
        const res = await publicRequest.get("/api/videos");
        console.log(res.data, "videos in home");
        const videos = res.data?.filter(
          (video) => video.subset === "other" || video.subset === "sokhanrani"
        );
        setVideoData(videos);
      } catch (error) {
        console.log(error);
      }
    };
    const getChannels = async () => {
      try {
        const res = await publicRequest.get("/api/podcastChannels");
        setPodcastData(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    getCourse();
    getVideos();
    getChannels();
  }, []);

  console.log(courseData, "Courses");

  // useEffect(() => {
  //   axios.get("http://localhost:9000/api/videos").then((response) => {
  //     const videos = response.data.filter(
  //       (video) => video.subset === "other" || video.subset === "sokhanrani"
  //     );
  //     setVideoData(videos);
  //   });
  // }, []);

  // useEffect(() => {
  //   axios
  //     .get("/api/podcastChannels")
  //     .then((response) => {
  //       setPodcastData(response.data);
  //     })
  //     .catch((error) => {});
  // }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function importAllImages(r) {
    let images = [];
    r.keys().forEach((item, index) => {
      images[item.replace("./", "")] = r(item);
    });
    return images;
  }

  const tvImagesObject = importAllImages(
    require.context("../../assets/channels/tv", false, /\.(png|jpg|svg)$/)
  );
  const radioImagesObject = importAllImages(
    require.context("../../assets/channels/radio", false, /\.(png|jpg|svg)$/)
  );
  const statesImagesObject = importAllImages(
    require.context("../../assets/channels/states", false, /\.(png|jpg|svg)$/)
  );

  return (
    <div className="home  w-full bg-center bg-cover mt-[3.5rem]">
      <div className="home__live text-center px-[2rem]">
        <BannerSlider />
      </div>
      <div className="home__channelCategory flex justify-between items-center text-text-light font-bold mt-[.5rem] mx-[2rem] mb-[.5rem] rounded-[1rem] bg-[#f5f9fc]">
        <div
          className={
            (channelSelected === "tv" &&
              "home__channelCategory__glider right-0") ||
            (channelSelected === "radio" &&
              "home__channelCategory__glider right-[33.3%]") ||
            (channelSelected === "states" &&
              "home__channelCategory__glider right-[66.6%]")
          }
        ></div>
        <div
          className={
            channelSelected === "tv"
              ? "home__channelCategory__item py-[1rem] text-[#fff]"
              : "home__channelCategory__item py-[1rem]"
          }
          onClick={() => setChannelSelected("tv")}
        >
          <p className="home__channelCategory--name ">تلوزیون</p>
        </div>
        <div
          className={
            channelSelected === "radio"
              ? "home__channelCategory__item py-[1rem] text-[#fff]"
              : "home__channelCategory__item py-[1rem]"
          }
          onClick={() => setChannelSelected("radio")}
        >
          <p className="home__channelCategory--name">رادیو</p>
        </div>
        <div
          className={
            channelSelected === "states"
              ? "home__channelCategory__item py-[1rem] text-[#fff]"
              : "home__channelCategory__item py-[1rem]"
          }
          onClick={() => setChannelSelected("states")}
        >
          <p className="home__channelCategory--name">استانی</p>
        </div>
        {/* <div
          className={
            channelSelected === "exclusive"
              ? "home__channelCategory__item py-[1rem] text-[#fff]"
              : "home__channelCategory__item py-[1rem]"
          }
          onClick={() => setChannelSelected("exclusive")}
        >
          <p className="home__channelCategory--name">اختصاصی</p>
        </div> */}
      </div>
      <div className="home__tvs px-[2rem]">
        <ChannelSlider
          category={channelSelected}
          imagesObject={
            (channelSelected === "tv" && tvImagesObject) ||
            (channelSelected === "radio" && radioImagesObject) ||
            (channelSelected === "states" && statesImagesObject)
          }
        />
      </div>
      <div className="home__cinema">
        <div className="home__cinema__shadow"></div>
        <div className="home__cinema__desc">
          <div className="home__cinema__desc__title">
            <h1 className="home__cinema__desc__title--heading  text-title-dark text-[2em] pt-4 pb-8 px-0">
              سینمای جهان اسلام
            </h1>
            <BsCameraReels className="home__cinema__desc__title--icon" />
          </div>
          <p className="home__cinema__desc--text">
            در قرآن من از تماشای فیلم ها و سریال های اسلامی لذت ببرید
          </p>
        </div>
        <div className="home__cinema__cards">
          <div className="home__cinema__cards__btn">
            <Link to={"series"}>
              <BsArrowLeft className="home__cinema__cards__btn--icon" />
            </Link>
          </div>
          <div className="home__cinema__cards__list">
            <Link to={"series"}>
              <div
                className="home__cinema__cards__list__card card1"
                style={{
                  backgroundImage: `url(${require("../../assets/mokhtar.jpg")})`,
                }}
              ></div>
            </Link>
            <div
              className="home__cinema__cards__list__card card2"
              style={{
                backgroundImage: `url(${require("../../assets/1.jpg")})`,
              }}
            ></div>
            <div
              className="home__cinema__cards__list__card card3"
              style={{
                backgroundImage: `url(${require("../../assets/mohammad.jpg")})`,
              }}
            ></div>
            <div
              className="home__cinema__cards__list__card card4"
              style={{
                backgroundImage: `url(${require("../../assets/message.jpg")})`,
              }}
            ></div>
          </div>
        </div>
      </div>
      <div className="home__quranSection h-[90vh]  bg-top-sec-bg-light flex flex-row-reverse text-right">
        <div className="home__quranSection__iconContainer   py-0 pr-8 pl-4 flex justify-center items-center basis-1/2">
          <img
            src={Quran}
            alt="quran"
            className="home__quranSection__iconContainer--icon   w-[35rem] h-[30rem]"
          />
        </div>
        <div className="home__quranSection__info   py-0 pr-4 pl-8 basis-1/2 flex items-end justify-center flex-col">
          <h1 className="home__quranSection__info--title  pl-[8rem] pt-[1rem] my-4 mx-0 text-[2.4em] text-title-dark font-bold">
            هر لحظه همراه با قرآن
          </h1>
          <p className="home__quranSection__info--subtitle   w-[80%] p-2 text-text-light  text-right">
            با بخش قرآن هر زمان که میخواهید قرآن بخوانید، گوش کنید و با معانی و
            مفاهیم آن آشنا شوید. دسترسی آسان به تمام سوره ها، امکان انتخاب زبان
            ترجمه و امکان انتخاب صوت قاریان مختلف
          </p>
          <Link
            to="/quran"
            className="home__quranSection__info--button mt-[3rem]  text-[#fff] bg-[#1d1d35] rounded-[1rem] text-[1.5em] px-[2rem] py-[.5rem] font-bold cursor-pointer"
          >
            تلاوت
          </Link>
        </div>
      </div>

      <div className="home__courses   pt-8 pb-16 px-8">
        <h1 className="home--title   text-title-dark text-[2rem] pt-4 pb-8 px-0">
          دوره های آموزشی
        </h1>
        <div className="home__courses__cards   gap-x-12 p-2 flex flex-wrap justify-evenly">
          {courseData.slice(0, 3).map((course) => (
            <CourseCard key={course._id} {...course} courseId={course._id} />
          ))}
        </div>
        <Link
          to={"courses"}
          className="home__courses__link   inline-block mt-16 py-[.7rem] px-8 text-text-light rounded-lg no-underline"
        >
          تمام دوره ها
        </Link>
      </div>

      <div className="home__player   w-full pt-8 px-12 pb-16 bg-color-darkest">
        <h1 className="home--title   text-color-light text-[2em] pt-4 pb-8 px-0">
          پخش کننده صوتی قرآن کریم
        </h1>
        <QuranPlayer />
      </div>
      {/* <div className="home__character pt-[4rem] mb-[4rem]">
        <h1 className="home--title   text-title-dark text-[2em] pb-[1rem] px-0">
          برترین چهره ها
        </h1>
        <Character />
      </div> */}

      <div className="home__topSection rounded-[1rem] mx-[2rem] my-[5rem] px-[2rem] h-[18rem] bg-top-sec-bg-light flex flex-row text-right">
        <div className="home__topSection__info   py-0 pr-4 pl-8 basis-1/2 flex items-end justify-center flex-col">
          <p className="home__topSection__info--title  pl-[8rem] pt-[1rem] my-4 mx-0 text-[2em] text-title-dark font-bold">
            <span className="mb-2 text-text-light block text-[.6em]">
              پیامبر اکرم(ص):
            </span>
            بهترین شما کسی است که قرآن را بیاموزد و آموزش دهد.
            <span className="mb-4 text-text-light block text-[.6em]">
              نهج الفصاحه، ح 1524.
            </span>
          </p>
        </div>
      </div>

      <div className="home__podcasts   pt-8 pb-12 px-8 ">
        <h1 className="home--title   text-title-dark text-[2em] pt-4 pb-8 px-0">
          پادکست ها
        </h1>
        <div className="home__podcasts__container">
          {podcastData.slice(0, 2).map((podcast) => (
            <Podcast key={podcast._id} {...podcast} podcastId={podcast._id} />
          ))}
        </div>
        <Link
          to={"podcast"}
          className="home__courses__link   inline-block mt-16 py-[.7rem] px-8 text-text-light rounded-lg no-underline"
        >
          تمام پادکست ها
        </Link>
      </div>

      <div className="home__videos   pt-8 pb-16 px-8">
        <h1 className="home--title   text-title-dark text-[2em] pt-4 pb-8 px-0">
          ویدئو ها
        </h1>
        <div className="home__videos__container">
          {videoData.slice(0, 5).map((video) => (
            <VideoCard key={video._id} {...video} videoId={video._id} />
          ))}
        </div>
        <Link
          to={"video"}
          className="home__courses__link   inline-block mt-16 py-[.7rem] px-8 text-text-light rounded-lg no-underline"
        >
          تمام ویدئو ها
        </Link>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
