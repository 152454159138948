import React, { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import SerialTopicSection from "../serialTopicSection/SerialTopicSection";

import "./serialTopic.scss";

const SerialTopic = ({ title, episode }) => {
  const [activeStatus, setActiveStatus] = useState(false);

  const handleHeaderClick = async () => {
    setActiveStatus(!activeStatus);
  };

  return (
    <div className="serialTopic">
      <div
        className={
          activeStatus
            ? "serialTopic__header serialTopic__header--active"
            : "serialTopic__header"
        }
        onClick={handleHeaderClick}
      >
        <div className="serialTopic__header--icon">
          {activeStatus ? <IoIosArrowUp /> : <IoIosArrowDown />}
        </div>
        <span className="serialTopic__header--title">{title}</span>
      </div>
      <div
        className="serialTopic__main"
        style={activeStatus ? { maxHeight: "100%" } : { maxHeight: "0" }}
      >
        {episode.map((episode) => (
          <SerialTopicSection
            key={episode._id}
            {...episode}
            id={episode._id}
            episode={episode}
          />
        ))}
      </div>
    </div>
  );
};

export default SerialTopic;
