import React from "react";
import { Link } from "react-router-dom";
import { FiPlayCircle } from "react-icons/fi";
import { toFarsiNumber } from "../../../utils/utilityFunctions";

import "./courseTopicSection.scss";

const courseTopicSection = ({ video, title, lectures, id }) => {
  const numberOfLectures = lectures?.length || 0;

  return (
    <div className="topicSection">
      <Link
        to={`/videoPlay/${id}`}
        className="topicSection__main"
        state={{ video, title }}
      >
        <h4 className="topicSection__main--title">{title}</h4>
        <span className="topicSection__main--id">
          {/* {toFarsiNumber(numberOfLectures)} */}
        </span>
      </Link>
      <div className="topicSection__detail">
        <div className="topicSection__detail__duration">
          <FiPlayCircle className="topicSection__detail__duration--icon" />
          <span className="topicSection__detail__duration--time">
            {/* {toFarsiNumber(10)}:{toFarsiNumber(45)} */}
          </span>
        </div>
        <span className="topicSection__detail--status">جلسه رایگان</span>
      </div>
    </div>
  );
};

export default courseTopicSection;
