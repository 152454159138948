import React from "react";
import { toFarsiNumber } from "../../utils/utilityFunctions";

import "./doubtFooter.scss";

export default function DoubtFooter({ solver, solveDate }) {
  return (
    <div className="doubtFooter">
      <h2> برطرف کننده شبهه : {solver} </h2>
      <h3>تاریخ برطرف شدن شبهه :{toFarsiNumber(solveDate)} </h3>
    </div>
  );
}
