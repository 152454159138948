import { useEffect, useState } from "react";
import { publicRequest } from "./request";

const useFetch = (url) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await publicRequest.get(url);
        setData(res.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError(error);
      }
    };
    getData();
  }, [url, refetch]);
  return { data, error, loading, refetch, setRefetch };
};

export default useFetch;
