import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
// import { useLocation } from "react-router-dom";
import { toFarsiNumber } from "../../utils/utilityFunctions";
import ArticleHeader from "../../Components/articleHeader/ArticleHeader";
import { useSelector } from "react-redux";

import "./doubt.scss";
import DoubtFooter from "../../Components/doubtFooter/DoubtFooter";
import { IoMdFlag } from "react-icons/io";

const Doubt = () => {
  // const [doubtTitle, setDoubtTitle] = useState("");
  // const getDoubtTitle = useLocation();
  const [doubt, setDoubt] = useState({});
  const { id } = useParams();

  useEffect(() => {
    axios
      .get(`/api/doubt/${id}`)
      .then((response) => {
        setDoubt(response.data);
      })
      .catch((error) => {
        console.error("Error fetching doubt data:", error);
      });
  }, [id]);

  const decodedCover = decodeURIComponent(doubt.cover);
  const coverfile = `/api/${decodedCover}`;

  return (
    <div className="doubt">
      <ArticleHeader headerTitle={doubt.title} />
      <div className="doubt__main">
        <div className="doubt__main__img">
          <img src={coverfile} alt="doubt" />
        </div>
        <div className="doubt__main__text">
          <p>{doubt.text}</p>

          <p className="article__header--report">
            گزارش تخلف
            <IoMdFlag size={24} style={{ marginTop: "-0.3rem" }} />
          </p>
        </div>
      </div>
      <DoubtFooter solver={doubt.solver} solveDate={doubt.solveDate} />
    </div>
  );
};

export default Doubt;
