import React, { useEffect, useState } from "react";
import axios from "axios";
import CourseCard from "../courseCard/CourseCard";

import "./courseContainer.scss";
import { useSelector } from "react-redux";

const CourseContainer = () => {
  const [courseData, setCourseData] = useState([]);
  const {
    courses: { courses },
  } = useSelector((state) => state);

  useEffect(() => {
    axios.get("/api/course").then((response) => {
      setCourseData(response.data);
    });
  }, []);

  const filteredCourses = courses.filter((course) => {
    return course.publishStatus == "published";
  });

  return (
    <div className="courseContainer">
      {filteredCourses.map((course) => (
        <CourseCard key={course._id} {...course} />
      ))}
    </div>
  );
};

export default CourseContainer;
