import React, { useEffect, useState } from "react";
import axios from "axios";
import { BsSortDown } from "react-icons/bs";
import ArticleCard from "../../Components/articleCard/ArticleCard";
import { useSelector } from "react-redux";

import "./articles.scss";
import useFetch from "../../request/useFetch";
// import Article from "../article/Article";
// import { Route, Switch } from "react-router-dom";

const Articles = () => {
  const [articlesData, setArticlesData] = useState([]);
  const [activeTab, setActiveTab] = useState("all");
  const [articles, setArticles] = useState([]);

  // useEffect(() => {
  //   axios.get("/api/article").then((response) => {
  //     setArticles(response.data);
  //   });
  // }, []);

  const { data, error, loading } = useFetch("/api/article");

  useEffect(() => {
    const filteredArticles = data.filter((article) => {
      if (activeTab === "all") {
        return article.publishStatus == "published";
      } else {
        return (
          article.subSet === activeTab && article.publishStatus == "published"
        );
      }
    });
    setArticlesData(filteredArticles);
  }, [data, activeTab]);

  return (
    !error &&
    !loading && (
      <div className="articles">
        <div className="articles__header">
          <h1>مقالات</h1>
          <span className="articles__header--icon"></span>
        </div>
        <div className="articles__filter">
          <div className="articles__filter__title">
            <span>: مرتب سازی</span>
            <BsSortDown
              fontSize="large"
              className="articles__filter__title--icon"
            />
          </div>
          <div className="articles__filter__items">
            <span
              onClick={() => {
                setActiveTab("all");
              }}
              className={
                activeTab === "all"
                  ? `articles__filter__items__btn filterActiveTab`
                  : `articles__filter__items__btn`
              }
            >
              همه مقالات
            </span>
            <span
              onClick={() => {
                setActiveTab("مقاله");
              }}
              className={
                activeTab === "مقاله"
                  ? `articles__filter__items__btn filterActiveTab`
                  : `articles__filter__items__btn`
              }
            >
              مقاله
            </span>
            <span
              onClick={() => {
                setActiveTab("تفسیر");
              }}
              className={
                activeTab === "تفسیر"
                  ? `articles__filter__items__btn filterActiveTab`
                  : `articles__filter__items__btn`
              }
            >
              تفسیر
            </span>
            <span
              onClick={() => {
                setActiveTab("روانشناسی");
              }}
              className={
                activeTab === "روانشناسی"
                  ? `articles__filter__items__btn filterActiveTab`
                  : `articles__filter__items__btn`
              }
            >
              روانشناسی
            </span>
            <span
              onClick={() => {
                setActiveTab("درس هایی از قرآن");
              }}
              className={
                activeTab === "درس های از قرآن"
                  ? `articles__filter__items__btn filterActiveTab`
                  : `articles__filter__items__btn`
              }
            >
              درس های از قرآن
            </span>
            <span
              onClick={() => {
                setActiveTab("داستان");
              }}
              className={
                activeTab === "داستان"
                  ? `articles__filter__items__btn filterActiveTab`
                  : `articles__filter__items__btn`
              }
            >
              داستان
            </span>
          </div>
        </div>
        <div className="articles__container">
          {articlesData.map((article) => (
            <ArticleCard key={article._id} {...article} />
          ))}
        </div>
      </div>
    )
  );
};

export default Articles;
