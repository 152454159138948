import React from "react";
import "./podcast.scss";
import { Link } from "react-router-dom";

const Podcast = ({ name, cover, podcastId, bio }) => {
  const decodedCover = decodeURIComponent(cover);
  const coverfile = `/api/${decodedCover}`;

  return (
    <div className="podcast h-44 flex flex-row-reverse text-right rounded-lg overflow-hidden cursor-pointer">
      <img
        src={coverfile}
        alt="cover"
        className="podcast--cover   w-44 h-full rounded-lg"
      />

      <div className="podcast__details   pr-2 flex flex-col justify-evenly flex-nowrap items-end">
        <h2 className="podcast__details--title   text-title-dark font-bold m-0">
          {name}
        </h2>
        <h4 className="podcast__details--channel   m-0 text-text-light">{} </h4>
        <p className="podcast__details--desc   m-0 text-text-light text-[.8em]">
          {bio}
        </p>
        <div className="card__text--options flex justify-end items-center">
          <Link
            to={"podcast"}
            className="card__text--link mb-8 p-2 text-btn-green-light hover:text-color-light underline-offset-8 rounded-[.5rem]"
          >
            پخش &larr;
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Podcast;
