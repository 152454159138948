import React, { useEffect, useState } from "react";
import axios from "axios";

import "./series.scss";
import SerialCard from "../../Components/serialCard/SerialCard";
import { publicRequest } from "../../request/request";
import useFetch from "../../request/useFetch";

const Series = () => {
  const [seriesDatas, setSeriesDatas] = useState([]);

  const { data, error, loading } = useFetch("/api/seriesData");

  useEffect(() => {
    setSeriesDatas(
      data.filter((serial) => serial.publishStatus == "published")
    );
  }, [data]);

  return (
    !error &&
    !loading && (
      <div className="series">
        {seriesDatas?.map((serial) => {
          return <SerialCard key={serial._id} {...serial} />;
        })}
      </div>
    )
  );
};

export default Series;
