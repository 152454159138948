import React from "react";
import { Link } from "react-router-dom";
import { FiPlayCircle } from "react-icons/fi";

import "./madahTopicSection.scss";

const MadahTopicSection = ({ vioce, title, voice, id }) => {
  const numberOfLectures = voice?.length || 0;

  return (
    <div className="topicSection">
      <Link
        to={`/madahiPlayer/${id}`}
        className="topicSection__main"
        state={{ vioce, title }}
      >
        <h4 className="topicSection__main--title">{title}</h4>
        <span className="topicSection__main--id">
          {/* {toFarsiNumber(numberOfLectures)} */}
        </span>
      </Link>
      <div className="topicSection__detail">
        <div className="topicSection__detail__duration">
          <FiPlayCircle className="topicSection__detail__duration--icon" />
          <span className="topicSection__detail__duration--time">
            {/* {toFarsiNumber(10)}:{toFarsiNumber(45)} */}
          </span>
        </div>
      </div>
    </div>
  );
};

export default MadahTopicSection;
