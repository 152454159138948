import React, { useState } from "react";
import "./courseDescription.scss";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

const CourseDescription = ({ description }) => {
  const [fullContent, setFullContent] = useState(false);
  return (
    <div className="courseDescription">
      <div className="courseDescription--title">
        <span>توضیحات</span>
      </div>
      <div className="courseDescription__detail">
        <div
          className="courseDescription__detail--content"
          style={fullContent ? { maxHeight: "100%" } : { maxHeight: "200px" }}
        >
          <p>{description}</p>
        </div>
        <div
          className={fullContent ? " " : "courseDescription__detail--shadow"}
        ></div>
      </div>
      <div className="courseDescription__showMore">
        <button
          className="courseDescription__showMore--btn"
          onClick={() => {
            setFullContent(!fullContent);
          }}
        >
          {fullContent ? <IoIosArrowUp /> : <IoIosArrowDown />}
          <span>{fullContent ? "مشاهده کمتر" : "مشاهده بیشتر"}</span>
        </button>
      </div>
    </div>
  );
};

export default CourseDescription;
