import React from "react";
import { toFarsiNumber } from "../../utils/utilityFunctions";
import PlayCircleFilledWhiteOutlinedIcon from "@mui/icons-material/PlayCircleFilledWhiteOutlined";

import { BsPerson } from "react-icons/bs";
import { BiTime } from "react-icons/bi";
import { FiStar } from "react-icons/fi";
import { HiOutlineUsers } from "react-icons/hi2";
import { Link } from "react-router-dom";

import "./serialCard.scss";

const SerialCard = ({ title, year, poster, imdbScore, _id }) => {
  const decodedPoster = decodeURIComponent(poster);
  const posterfile = `/api/${decodedPoster}`;

  return (
    <div className="documentaryCard">
      <Link to={`/serial/${_id}`}>
        <div className="documentaryCard__image">
          <img src={posterfile} alt="cover" />
          <div className="documentaryCard__image__hover">
            <PlayCircleFilledWhiteOutlinedIcon className="documentaryCard__image__hover__icon" />
          </div>
        </div>
      </Link>
      <div className="documentaryCard__container">
        <div className="documentaryCard__container__title">
          <span>{title}</span>
        </div>
        <div className="courseCard__footer__top__rating">
          <FiStar className="courseCard__footer__top__rating--icon" />
          <span>IMDB</span>
          <span>{toFarsiNumber(imdbScore)}</span>
        </div>
        <div className="documentaryCard__container__detail">
          <div className="courseCard__footer__bottom">
            <div className="documentaryCard__container__detail__date">
              <span>{toFarsiNumber(year)}</span>
              <span>سال تولید</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SerialCard;
