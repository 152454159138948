import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import thunk from "redux-thunk";
import authSlice from "../reducers/auth/authSlice";
import loginSlice from "../reducers/auth/loginSlice";
import quranSlice from "../reducers/quran/quranSlice";
import courseSlice from "../reducers/course/courseSlice";
import doubtsSlice from "../reducers/doubts/doubtsSlice";
import articlesSlice from "../reducers/articles/articlesSlice";

const persistConfig = {
  key: "root",
  storage,
  stateReconciler: autoMergeLevel2,
};

const rootReducer = combineReducers({
  quran: quranSlice,
  showAuthBox: authSlice,
  showLoginBox: loginSlice,
  courses: courseSlice,
  articles: articlesSlice,
  doubts: doubtsSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export const persistor = persistStore(store);
