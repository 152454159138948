import { useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import "./watch.scss";
import VideoJS from "./VideoJS";

export default function Watch() {
  const [data, setData] = useState({});

  const videoJsOptions = {
    autoplay: true,
    controls: true,
    responsive: true,
    fluid: true,

    sources: [
      {
        src: `/api${data?.video}/index.m3u8`,
        type: "application/x-mpegURL",
      },
    ],
  };

  const elementRef = useRef();

  return (
    <div className="watch" ref={elementRef}>
      <div className="watch__main">
        <div className="watch__main__right">
          <div className="watch__main__right--video">
            <VideoJS options={videoJsOptions} videoId={data._id} />
          </div>
        </div>
      </div>
    </div>
  );
}
