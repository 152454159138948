import React, { useEffect, useState } from "react";
import axios from "axios";
import { AiTwotoneStar } from "react-icons/ai";
import { FaRegCopy } from "react-icons/fa";
import { toFarsiNumber } from "../../utils/utilityFunctions";

import "./serialSidebar.scss";

const SerialSidebar = ({ serialId }) => {
  const [courseInfo, setCourseInfo] = useState();

  useEffect(() => {
    axios
      .get(`/api/course/${serialId}`)
      .then((response) => {
        setCourseInfo(response.data);
      })
      .catch((error) => {
        console.error("Error fetching course information:", error);
      });
  }, [serialId]);

  return (
    <div className="serialSidebar">
      <div className="serialSidebar__box">
        <div className="serialSidebar__box__info">
          <div className="serialSidebar__box__info__student">
            <span>{toFarsiNumber(courseInfo?.ratingQuantity || 0)}</span>
            <p>رضایت</p>
          </div>
          <div className="serialSidebar__box__info__rate">
            <div className="serialSidebar__box__info__rate__text">
              <span>{toFarsiNumber(courseInfo?.ratingAvg || 0)}</span>
              <p>امتیاز</p>
            </div>
            <AiTwotoneStar className="serialSidebar__box__info__rate--icon" />
          </div>
        </div>
        {/* <div className="serialSidebar__box__progress">
          <div className="courseSidebar__box__info__student__text">
            <span>{courseInfo.actors}</span>
            <p>بازیگران</p>
          </div>
        </div> */}
      </div>

      {/* course link */}
      <div className="serialSidebar__box">
        <div className="serialSidebar__box__linkHeader">
          <span>لینک کوتاه</span>
        </div>
        <div className="serialSidebar__box__linkBox">
          <span>https://qoraani.com/?p=128</span>
          <FaRegCopy className="serialSidebar__box__linkBox--icon" />
        </div>
      </div>
    </div>
  );
};

export default SerialSidebar;
