import axios from "axios";

const getArticles = async () => {
  const response = await axios.get("/api/article");
  return response.data;
};

const articlesService = {
  getArticles,
};

export default articlesService;
