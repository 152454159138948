import React, { useEffect, useState } from "react";
import axios from "axios";
import { BsPeopleFill } from "react-icons/bs";
import { AiTwotoneStar } from "react-icons/ai";
import { FaRegCopy } from "react-icons/fa";
import { toFarsiNumber } from "../../../utils/utilityFunctions";
import { Link } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import "./courseSidebar.scss";

const CourseSidebar = ({ _id, courseId, cover }) => {
  const [courseInfo, setCourseInfo] = useState();

  useEffect(() => {
    // Fetch course information based on the courseId
    axios
      .get(`/api/course/${courseId}`)
      .then((response) => {
        setCourseInfo(response.data);
      })
      .catch((error) => {
        console.error("Error fetching course information:", error);
      });
  }, [courseId]);

  const decodedCover = decodeURIComponent(cover);
  const coverfile = `/api/${decodedCover}`;

  return (
    <div className="courseSidebar">
      {/* student count and course rate and progress */}
      <div className="courseSidebar__box">
        <div className="courseSidebar__box__info">
          <div className="courseSidebar__box__info__student">
            <div className="courseSidebar__box__info__student__text">
              <span>{toFarsiNumber(courseInfo?.students || 0)}</span>
              <p>دانشجو</p>
            </div>
            <BsPeopleFill className="courseSidebar__box__info__student--icon" />
          </div>
          <div className="courseSidebar__box__info__rate">
            <div className="courseSidebar__box__info__rate__text">
              <span>{toFarsiNumber(courseInfo?.ratingQuantity || 0)}</span>
              <p>رضایت</p>
            </div>
            <AiTwotoneStar className="courseSidebar__box__info__rate--icon" />
          </div>
        </div>
        <div className="courseSidebar__box__progress">
          <div className="courseSidebar__box__progress__header">
            <span>قیمت دوره</span>
            <p>{toFarsiNumber("1,000,000")} تومان</p>
          </div>
          <div className="courseSidebar__box__progress__bar">
            <p>{toFarsiNumber("1,000,000")} تومان</p>
          </div>
        </div>
      </div>
      {/* course teacher info */}
      <div className="courseSidebar__box">
        {courseInfo?.instructor?.profilePic ? (
          <img
            src={coverfile}
            alt="course teacher"
            className="courseSidebar__box__teacherImage"
          />
        ) : (
          <div className="courseSidebar__box__teacherIcon">
            <AccountCircleIcon style={{ fontSize: "5rem" }} />
          </div>
        )}
        {/* <Link to={`/teacher/${courseInfo?.instructor?._id}`}></Link> */}
        <span className="courseSidebar__box__teacherTitle">
          {courseInfo?.instructor?.fullName || ""}
        </span>
      </div>
      {/* course link */}
      <div className="courseSidebar__box">
        <div className="courseSidebar__box__linkHeader">
          <span>لینک کوتاه</span>
        </div>
        <div className="courseSidebar__box__linkBox">
          <span>https://qoraani.com/?p=128</span>
          <FaRegCopy className="courseSidebar__box__linkBox--icon" />
        </div>
      </div>
    </div>
  );
};

export default CourseSidebar;
