import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import CommentCard from "../commentCard/CommentCard";
import CommentForm from "../commentForm/CommentForm";

import "./commentBox.scss";

const CommentBox = ({ review }) => {
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newCommentBoxStatus, setNewCommentBoxStatus] = useState(false);
  const [commentReplyTo, setCommentReplyTo] = useState("");
  const { id: courseId } = useParams();

  function newCommentHandler() {
    setNewCommentBoxStatus(true);
    setCommentReplyTo("");
  }

  return (
    <div className="commentBox" id="comment-box">
      <div className="commentBox__header">
        <Link to="#">
          <button
            className="commentBox__header--newComment"
            onClick={newCommentHandler}
          >
            ایجاد نظر جدید
          </button>
        </Link>
        <div className="commentBox__header--title">
          <h3>نظرات</h3>
        </div>
      </div>
      <div className="commentBox__container">
        {newCommentBoxStatus && (
          <CommentForm
            setCommentReplyTo={setCommentReplyTo}
            setNewCommentBoxStatus={setNewCommentBoxStatus}
            commentReplyTo={commentReplyTo}
            courseId={courseId}
          />
        )}

        {review.length > 0 ? (
          review.map((comment) => (
            <CommentCard
              key={comment._id}
              {...comment}
              setNewCommentBoxStatus={setNewCommentBoxStatus}
              setCommentReplyTo={setCommentReplyTo}
            />
          ))
        ) : loading ? (
          <p>Loading...</p>
        ) : (
          <p>هیچ نظری وجود ندارد</p>
        )}
      </div>
    </div>
  );
};

export default CommentBox;
