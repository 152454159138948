import React from "react";
import "./documentaryCard.scss";
import { toFarsiNumber } from "../../utils/utilityFunctions";
import PlayCircleFilledWhiteOutlinedIcon from "@mui/icons-material/PlayCircleFilledWhiteOutlined";
import { Link } from "react-router-dom";
import moment from "moment-jalali";

const subsetTranslations = {
  documentary: "مستند",
};

const documentaryCard = ({
  title,
  description,
  cover,
  _id,
  video,
  subset,
  createdAt,
}) => {
  const translatedSubset = subsetTranslations[subset] || subset;

  const jalaliDate = moment(createdAt).format("jYYYY");

  const decodedCover = decodeURIComponent(cover);
  const coverfile = `/api/${decodedCover}`;

  return (
    <div className="documentaryCard">
      <Link to={`/videoPlay/${_id}`} state={{ video, title }}>
        <div className="documentaryCard__image">
          <img src={coverfile} alt="cover" />
          <div className="documentaryCard__image__hover">
            <PlayCircleFilledWhiteOutlinedIcon className="documentaryCard__image__hover__icon" />
          </div>
        </div>
      </Link>
      <div className="documentaryCard__container">
        <div className="documentaryCard__container__title">
          <span>{title}</span>
        </div>
        <div className="documentaryCard__container__source">
          <span>...{description.split(" ").slice(0, 10).join(" ")}</span>
        </div>
        <div className="documentaryCard__container__detail">
          <div className="documentaryCard__container__detail__view">
            <span>{translatedSubset}</span>
          </div>
          <div className="documentaryCard__container__detail__date">
            <span>{toFarsiNumber(jalaliDate)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default documentaryCard;
