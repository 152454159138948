import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import ArticleHeader from "../../Components/articleHeader/ArticleHeader";
import ArticleFooter from "../../Components/articleFooter/ArticleFooter";

import "./article.scss";

const Article = () => {
  const [article, setArticle] = useState({});
  const { id } = useParams();

  useEffect(() => {
    axios
      .get(`/api/article/${id}`)
      .then((response) => {
        setArticle(response.data);
      })
      .catch((error) => {
        console.error("Error fetching article data:", error);
      });
  }, [id]);

  const decodedCover = decodeURIComponent(article.cover);
  const coverfile = `/api/${decodedCover}`;

  return (
    <div className="article">
      <ArticleHeader title={article.title} />
      <div className="doubt__main">
        <div className="doubt__main__img">
          <img src={coverfile} alt="article" />
        </div>
        <div className="doubt__main__text">
          <p>{article.text}</p>
        </div>
      </div>
      <ArticleFooter writer={article.writer} />
    </div>
  );
};

export default Article;
