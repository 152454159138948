import {
  HashRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useContext, useEffect } from "react";

import MobileLogin from "./Components/MobileAuth/MobileLogin";
import MobileAuth from "./Components/MobileAuth/MobileAuth";
import Navbar from "./Components/navbar/Navbar";
import Profile from "./pages/profile/Profile";
import Quran from "./pages/quran/Quran";
import Surah from "./pages/surah/Surah";
import Watch from "./pages/watch/Watch";
import Home from "./pages/home/Home";
import "./App.scss";
// import { getSurahs, getAllJuzs, getArticles } from "./path-to-your-functions";
import Video from "./pages/video/Video";
import Doubt from "./pages/doubt/Doubt";
import Course from "./pages/course/Course";
import Doubts from "./pages/doubts/Doubts";
import IFrame from "./pages/iframe/IFrame";
import Courses from "./pages/courses/Courses";
import Article from "./pages/article/Article";
import Teacher from "./pages/teacher/Teacher";
import Podcast from "./pages/podcast/Podcast";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Articles from "./pages/articles/Articles";
import VideoPlay from "./pages/videoPlay/VideoPlay";
import CourseList from "./pages/courseList/CourseList";
import { AuthContext } from "./authContext/AuthContext";
import Documentary from "./pages/documentary/Documentary";
import PodcastPlay from "./pages/podcastPlay/PodcastPlay";
import { getDoubts } from "./reducers/doubts/doubtsSlice";
import { getCourses } from "./reducers/course/courseSlice";
import PodcastEpisode from "./pages/podcastEpisodes/PodcastEpisode";
import PodcastPlayer from "./Components/podcastPlayer/PodcastPlayer";
import Series from "./pages/series/Series";
import Serial from "./pages/serial/Serial";
import Madahi from "./pages/madahi/Madahi";
import Madah from "./pages/madah/Madah";
import MadahiPlayer from "./Components/madahiPlayer/MadahiPlayer";
import Terms from "./Components/terms/Terms";
import ContactUs from "./pages/contactUs.jsx/ContactUs";
import Subscription from "./Components/subscription/Subscription";
import CourseSubscription from "./Components/courseSubscription/CourseSubscription";

function App() {
  const dispatch = useDispatch();
  const { user } = useContext(AuthContext);
  const {
    showAuthBox: { showAuthBox },
    showLoginBox: { showLoginBox },
  } = useSelector((state) => state);

  // useEffect(() => {
  //   dispatch(getSurahs());
  //   dispatch(getAllJuzs());
  //   dispatch(getCourses());
  //   dispatch(getDoubts());
  //   dispatch(getArticles());
  // }, []);

  return (
    <div className="App">
      <Router>
        {showAuthBox && <MobileAuth />}
        {showLoginBox && <MobileLogin />}
        {user && <ToastContainer />}
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/quran" exact element={<Quran />} />
          <Route path="/quran/:surahNumber" element={<Surah />} />
          <Route path="/live" element={<IFrame />} />
          <Route path="/podcast" element={<Podcast />} />
          <Route path="/podcastEpisode/:id" element={<PodcastEpisode />} />
          <Route
            path="/podcastPlayer/channel/:channelId/"
            element={<PodcastPlayer />}
          />
          <Route path="/MadahiPlayer/:id" element={<MadahiPlayer />} />
          <Route path="/madahi" element={<Madahi />} />
          <Route path="/madah/:id" element={<Madah />} />
          <Route path="/video" element={<Video />} />
          {/* <Route path="/courseList" element={<CourseList />} /> */}
          <Route path="/courses" element={<Courses />} />
          <Route path="/courseList" element={<CourseList />} />
          <Route path="/course/:id" element={<Course />} />
          <Route path="/series" element={<Series />} />
          <Route path="/serial/:id" element={<Serial />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/doubts" element={<Doubts />} />
          <Route path="/doubt/:id" element={<Doubt />} />
          <Route path="/documentary" element={<Documentary />} />
          <Route path="/articles" element={<Articles />} />
          <Route path="/article/:id" element={<Article />} />
          <Route path="/subscription" element={<Subscription />} />
          <Route path="/courseSubscription" element={<CourseSubscription />} />
          {/* <Route path="/teacher" element={<Teacher />} /> */}
          <Route path="/videoPlay/:id" element={<VideoPlay />} />
          {user ? (
            <>
              <Route path="/watch" element={<Watch />} />
              <Route path="/profile/:placeholder" element={<Profile />} />
            </>
          ) : (
            <Route path="*" element={<Navigate to="/" replace />} />
          )}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
