import React from "react";
import "./videoCard.scss";
import { toFarsiNumber } from "../../utils/utilityFunctions";
import PlayCircleFilledWhiteOutlinedIcon from "@mui/icons-material/PlayCircleFilledWhiteOutlined";
import { Link } from "react-router-dom";
import moment from "moment-jalali";

const subsetTranslations = {
  sokhanrani: "سخنرانی",
  other: "غیره",
};

const VideoCard = ({
  title,
  cover,
  description,
  _id,
  video,
  subset,
  createdAt,
}) => {
  const translatedSubset = subsetTranslations[subset] || subset;

  const jalaliDate = moment(createdAt).format("jYYYY");

  const decodedCover = decodeURIComponent(cover);
  const coverfile = `/api/${decodedCover}`;
  return (
    <div className="videoCard">
      <Link to={`/videoPlay/${_id}`} state={{ video, title, description }}>
        <div className="videoCard__image">
          <img src={coverfile} alt="cover" />
          <div className="videoCard__image__hover">
            <PlayCircleFilledWhiteOutlinedIcon className="videoCard__image__hover__icon" />
          </div>
        </div>
      </Link>
      <div className="videoCard__container">
        <div className="videoCard__container__title">
          <span>{title}</span>
        </div>
        <div className="videoCard__container__source">
          <span>{description.split(" ").slice(0, 4).join(" ")}...</span>
        </div>
        <div className="videoCard__container__detail">
          <div className="videoCard__container__detail__view">
            <span>{translatedSubset}</span>
          </div>
          <div className="videoCard__container__detail__date">
            <span>{toFarsiNumber(jalaliDate)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoCard;
