import React from "react";
import "./courseBoxHeader.scss";
import { Link } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";

const CourseBoxHeader = ({ headerTitle, headerInfo, courseName }) => {
  return (
    <div className="courseBoxHeader">
      <div className="courseBoxHeader__btn">
        <AiOutlineArrowLeft />
        <Link to="/courses" state={{ courseName: courseName }}>
          مشاهده همه دوره ها
        </Link>
      </div>
      <div className="courseBoxHeader__title">
        <h2>{headerTitle}</h2>
        <p>{headerInfo}</p>
      </div>
    </div>
  );
};

export default CourseBoxHeader;
