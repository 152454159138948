import React from "react";
import { Link } from "react-router-dom";
import { BsPerson } from "react-icons/bs";
// import axios from "axios";
// import { toFarsiNumber } from "../../utils/utilityFunctions";
// import { FiStar } from "react-icons/fi";
// import { FaEye } from "react-icons/fa";

import "./articleCard.scss";

const ArticleCard = ({ _id, title, cover, writer, category, subSet }) => {
  const decodedCover = decodeURIComponent(cover);
  const coverfile = `/api/${decodedCover}`;

  return (
    <div className="articleCard">
      <Link to={`/article/${_id}`}>
        <div className="articleCard__image">
          <img src={coverfile} alt={"article cover"} />
        </div>
      </Link>
      <div className="articleCard__box">
        <div className="articleCard__box__tags">
          <div className="articleTag">
            <span>{category}</span>
          </div>
        </div>
        <div className="articleCard__box__title">
          <Link to={`/article/${_id}`}>
            <span>{title.split(" ").slice(0, 9).join(" ")}</span>
          </Link>
        </div>
        <div className="articleCard__box__description">
          <p>{subSet}</p>
        </div>
      </div>
      <div className="articleCard__footer">
        <div className="articleCard__footer__top">
          <div className="articleCard__footer__top__author">
            <span>{writer}</span>
            <BsPerson className="articleCard__footer__top__author--icon" />
          </div>
          {/* <div className="articleCard__footer__top__view">
            <span>{toFarsiNumber(725)}</span>
            <FaEye />
          </div>
          <div className="articleCard__footer__top__rating">
            <FiStar className="articleCard__footer__top__rating--icon" />
            <span>{toFarsiNumber(5.0)}</span>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ArticleCard;
