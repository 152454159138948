import React, { useEffect, useState } from "react";
import "./courseSlider.scss";
import axios from "axios";

import CourseCard from "../../courseComponents/courseCard/CourseCard";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { useSelector } from "react-redux";

const CourseSlider = () => {
  const [itemPerViewCount, setItemPerViewCount] = useState(4);
  const [courseData, setCourseData] = useState([]);

  const {
    courses: { courses },
  } = useSelector((state) => state);

  const filteredCourses = courses.filter((course) => {
    return course.publishStatus == "published";
  });

  const userWindowWidth = window.innerWidth;

  useEffect(() => {
    sliderPerViewSet();
  }, []);

  function sliderPerViewSet() {
    if (userWindowWidth > 1200) {
      setItemPerViewCount(4);
    } else if (userWindowWidth > 1025 && userWindowWidth < 1200) {
      setItemPerViewCount(3);
    } else if (userWindowWidth > 521 && userWindowWidth < 1025) {
      setItemPerViewCount(2);
    } else if (userWindowWidth < 520) {
      setItemPerViewCount(1);
    }
  }

  return (
    <div className="courseSlider">
      <Swiper
        slidesPerView={itemPerViewCount}
        spaceBetween={30}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, Pagination]}
        className="mySwiper"
      >
        {filteredCourses.map((course) => (
          <SwiperSlide>
            <CourseCard key={course._id} {...course} isSlider={true} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default CourseSlider;
