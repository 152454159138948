import React from "react";
import { TiTick } from "react-icons/ti";

import CourseSubscriptionCard from "../courseSubscriptionCard/CourseSubscriptionCard.";

import "./courseSubscription.scss";

const CourseSubscription = () => {
  return (
    <div className="courseSubscription">
      <div className="courseSubscription__Container">
        <h1 className="courseSubscription__Container--title">خرید دوره</h1>
      </div>
      <hr />
      <p className="subscription__footnote1">
        <TiTick />
        دوره آموزش تفسیر
      </p>

      <div className="courseSubscription__body">
        <CourseSubscriptionCard
          wallet={"wallet1"}
          price={"‍۱,۰۰۰,۰۰۰"}
          button={"خرید"}
        />
        <CourseSubscriptionCard
          wallet={"wallet2"}
          price={"رایگان"}
          button={"دریافت"}
        />
      </div>
    </div>
  );
};

export default CourseSubscription;
