import React, { useState } from "react";
import { Link } from "react-router-dom";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import moment from "moment-jalali";

import "./videoCard.scss";

const subsetTranslations = {
  sokhanrani: "سخنرانی",
  other: "غیره",
};

const VideoCard = ({
  cover,
  title,
  description,
  videoId,
  subset,
  _id,
  createdAt,
  video,
}) => {
  const [showPlayIcon, setShowPlayIcon] = useState(false);

  const translatedSubset = subsetTranslations[subset] || subset;

  const jalaliDate = moment(createdAt).format("jYYYY");

  const decodedCover = decodeURIComponent(cover);
  const coverfile = `/api/${decodedCover}`;

  return (
    <Link
      to={`/videoPlay/${_id}`}
      state={{ video, title, description }}
      className="videoCard   w-[17rem] h-[17rem] rounded-lg overflow-hidden text-right no-underline"
    >
      <div
        className="videoCard__coverContainer   w-full h-[60%] relative"
        onMouseEnter={() => setShowPlayIcon(true)}
        onMouseLeave={() => setShowPlayIcon(false)}
      >
        <img
          className="videoCard__coverContainer--cover   w-full h-full"
          src={coverfile}
          alt="cover"
        />

        {showPlayIcon && (
          <div className="videoCard__coverContainer--playIcon   w-full h-full backdrop-blur-[5px] absolute flex justify-center items-center top-0 left-0">
            <PlayCircleOutlineIcon sx={{ fontSize: 70, color: "#f5f9fc" }} />
          </div>
        )}
      </div>
      <div className="videoCard__details   h-[38%] p-2 flex flex-col justify-between">
        <h5 className="videoCard__details--title  line-clamp-2 text-title-dark">
          {title}
        </h5>
        <h6 className="videoCard__details--channel   text-text-light text-[.8em] font-bold">
          {description}
        </h6>
        <p className="videoCard__details--spec   text-text-light text-[.7em]">
          <span className=" ml-4 mt-40 text-text-light">
            {translatedSubset}
          </span>
          <span className=" ml-4 mt-40 text-text-light">۶ مرداد ۱۴۰۱</span>
        </p>
      </div>
    </Link>
  );
};

export default VideoCard;
