import React, { useEffect, useState } from "react";
import { toFarsiNumber } from "../../utils/utilityFunctions";

import "./serialContent.scss";
import SerialTopic from "../serialTopic/SerialTopic";

const SerialContent = ({ seasonss }) => {
  useEffect(() => {}, []);

  return (
    <div className="serialContent">
      <div className="serialContent__header">
        <p>{/* {toFarsiNumber(54)}:{toFarsiNumber(30)} */}</p>
        <h2>فصل</h2>
      </div>
      {seasonss.map((seasons) => (
        <SerialTopic key={seasons._id} {...seasons} />
      ))}
    </div>
  );
};

export default SerialContent;
