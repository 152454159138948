import React, { useEffect, useState } from "react";
import axios from "axios";
import PodcastCard from "../../Components/podcastCard/PodcastCard";

import "./podcast.scss";
import useFetch from "../../request/useFetch";

const Podcast = () => {
  const [podcastData, setPodcastData] = useState([]);

  useEffect(() => {
    axios
      .get("/api/podcastChannels")
      .then((response) => {
        const Data = response.data.filter((podcast) => podcast.publish);
        setPodcastData(Data);
      })
      .catch((error) => {});
  }, []);

  // const filteredPodcasts = podcasts.filter(
  //   (podcast) => podcast.published && podcast.type === "podcast"
  // );

  // const Podcasts = podcastData.filter((podcast) => podcast.type === "podcast");
  const { data, error, loading } = useFetch("/api/podcastChannels");

  useEffect(() => {
    // setPodcastData(data.filter((d) => d.publishStatus == "published"));
  }, [data]);
  return (
    !error &&
    !loading && (
      <>
        <div className="podcast">
          {data.map((podcast) => (
            <PodcastCard key={podcast._id} {...podcast} />
          ))}
        </div>
      </>
    )
  );
};

export default Podcast;
