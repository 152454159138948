import React from "react";
import { Link } from "react-router-dom";
import "./courseCard.scss";

const CourseCard = ({ title, quickDescription, cover, courseId }) => {
  const decodedCover = decodeURIComponent(cover);
  const coverfile = `/api/${decodedCover}`;

  return (
    <div className="card flex flex-col w-[23rem] h-full text-right gap-y-4 items-end">
      <img
        src={coverfile}
        className="card--image w-full h-[60%] rounded-lg"
        alt="cardImage"
      />
      <div className="card__text flex flex-col items-start flex-nowrap justify-center">
        <h2 className="card__text--title m-0 py-2 px-0 text-title-dark text-[1.5rem] font-bold">
          {title}
        </h2>
        <p className="card__text--desc m-0 text-[1rem]">{quickDescription}</p>
        <div className="card__text--options flex justify-end items-center">
          <Link
            to={`/course/${courseId}`} // تغییر این قسمت
            className="card__text--link mb-8 p-2 text-btn-green-light hover:text-color-light underline-offset-8 rounded-[.5rem]"
          >
            ثبت نام &larr;
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CourseCard;
