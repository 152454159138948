import React, { useEffect, useState } from "react";
import axios from "axios";

import "./course.scss";

import CourseDescription from "../../Components/courseComponents/courseDescription/CourseDescription";
import CourseInfoBox from "../../Components/courseComponents/courseInfoBox/CourseInfoBox";
import CourseContent from "../../Components/courseComponents/courseContent/CourseContent";
import CourseSidebar from "../../Components/courseComponents/courseSidebar/CourseSidebar";
import CommentBox from "../../Components/commentBox/CommentBox";
import { toFarsiNumber } from "../../utils/utilityFunctions";
import { HiOutlineBriefcase } from "react-icons/hi";
import { FaRegCalendarAlt } from "react-icons/fa";
import { HiOutlineClock } from "react-icons/hi";
import { BsCameraVideo } from "react-icons/bs";
import { BsShieldCheck } from "react-icons/bs";
import { BsInfoSquare } from "react-icons/bs";
import { BsPeople } from "react-icons/bs";
import { Link, useParams } from "react-router-dom";
import { IoMdFlag } from "react-icons/io";

const Course = () => {
  const [courseData, setCourseData] = useState();
  const { id } = useParams();

  useEffect(() => {
    axios
      .get(`/api/course/${id}`)
      .then((response) => {
        setCourseData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching course data:", error);
      });
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const lastUpdate = `${toFarsiNumber(1402)}/ ${toFarsiNumber(
    6
  )} / ${toFarsiNumber(2)} `;

  const courseDuration = ` ساعت ${toFarsiNumber(54)}`;

  const decodedCover = decodeURIComponent(courseData?.cover || "");
  const coverfile = `/api/${decodedCover}`;

  return (
    <div className="course">
      <div className="course__header">
        <div className="course__header__cover">
          <img src={coverfile} alt="course cover" />
        </div>
        <div className="course__header__detail">
          <div className="course__header__detail__text">
            <h2 className="course__header__detail__text__title">
              {courseData?.title || "Loading..."}
            </h2>
            <p className="course__header__detail__text__description">
              {courseData?.quickDescription || "Loading..."}
            </p>
          </div>
          <div className="course__header__detail__signUp">
            <div className="course__header__detail__signUp__price">
              <span>
                <p
                  className="movie__info__container--report"
                  // onClick={() => {
                  //   if (courseData.user) {
                  //     setShowReportForm(true);
                  //   } else {
                  //     dispatch(showLoginBox());
                  //   }
                  // }}
                >
                  گزارش تخلف
                  <IoMdFlag size={24} style={{ marginTop: "-0.3rem" }} />
                </p>
              </span>
            </div>
            <Link to={`/courseSubscription`}>
              <div className="course__header__detail__signUp--btn">
                <span>ثبت نام در دوره</span>
                <BsShieldCheck />
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="course__container">
        <CourseSidebar courseId={id} />
        <div className="course__container__main">
          <div className="course__container__main__info">
            <CourseInfoBox
              title="وضعیت دوره"
              icon={<FaRegCalendarAlt fontSize="2.5rem" />}
              detail={courseData?.status || "Loading..."}
            />
            <CourseInfoBox
              icon={<HiOutlineClock fontSize="2.5rem" />}
              title=" مدت زمان دوره"
              detail={courseData?.duration || "Loading..."}
            />
            <CourseInfoBox
              icon={<BsInfoSquare fontSize="2.5rem" />}
              title=" آخرین بروزرسانی"
              detail={courseData?.updatedAt || "Loading..."}
            />
            <CourseInfoBox
              icon={<BsCameraVideo fontSize="2.5rem" />}
              title=" نوع مشاهده"
              detail={courseData?.format || "Loading..."}
            />
            <CourseInfoBox
              icon={<HiOutlineBriefcase fontSize="2.5rem" />}
              title=" پیش نیاز"
              detail={courseData?.requirements || "Loading..."}
            />
            <CourseInfoBox
              icon={<BsPeople fontSize="2.5rem" />}
              title=" روش پشتیبانی"
              detail="ایمیل"
            />
          </div>
          <CourseDescription
            description={courseData?.description || "Loading..."}
          />
          <CourseContent sessions={courseData?.sessions || []} />

          <CommentBox review={courseData?.review || []} />
        </div>
      </div>
    </div>
  );
};

export default Course;
