import React from "react";
import "./courseCard.scss";
import { toFarsiNumber } from "../../../utils/utilityFunctions";
import { BsPerson } from "react-icons/bs";
import { BiTime } from "react-icons/bi";
import { FiStar } from "react-icons/fi";
import { HiOutlineUsers } from "react-icons/hi2";
import { Link } from "react-router-dom";

const CourseCard = ({
  title,
  instructor,
  category,
  quickDescription,
  cover,
  ratingAvg,
  studentCount,
  duration,
  _id,
}) => {
  const decodedCover = decodeURIComponent(cover);
  const coverfile = `/api/${decodedCover}`;

  return (
    <div className="courseCard">
      <Link to={`/course/${_id}`}>
        <div className="courseCard__image">
          <img src={coverfile} alt="course cover" />
        </div>
      </Link>
      <div className="courseCard__box">
        <div className="courseCard__box__tags">
          <div className="courseTag">
            <span>{category}</span>
          </div>
        </div>
        <Link to={`/course/${_id}`}>
          <div className="courseCard__box__title">
            <span>{title}</span>
          </div>
        </Link>
        <div className="courseCard__box__description">
          <p>{quickDescription.split(" ").slice(0, 4).join(" ")}</p>
        </div>
      </div>
      <div className="courseCard__footer">
        <div className="courseCard__footer__top">
          {/* <div className="courseCard__footer__top__teacher">
            <Link to="/teacher">
              <span>{instructor.fullName}</span>
            </Link>
            <BsPerson className="courseCard__footer__top__teacher--icon" />
          </div> */}
          <div className="courseCard__footer__top__time">
            <span>{duration}</span>
            <BiTime className="courseCard__footer__top__time--icon" />
          </div>
          <div className="courseCard__footer__top__rating">
            <FiStar className="courseCard__footer__top__rating--icon" />
            <span>{toFarsiNumber(ratingAvg)}</span>
          </div>
        </div>
        <div className="courseCard__footer__bottom">
          <div className="courseCard__footer__bottom__student">
            <span>{toFarsiNumber(studentCount)}</span>
            <HiOutlineUsers />
          </div>
          {/* <div className="courseCard__footer__bottom__price">
            <span>تومان</span>
            <span>{toFarsiNumber(690000)}</span>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default CourseCard;
