import { useEffect, useState } from "react";
import axios from "axios";
import DoubtsCard from "../../Components/doubtsCard/DoubtsCard";
import ArticleHeader from "../../Components/articleHeader/ArticleHeader";
import { BsSortDown } from "react-icons/bs";

import "./doubts.scss";
import useFetch from "../../request/useFetch";

const Doubts = () => {
  const [activeTab, setActiveTab] = useState("all");
  const [articles, setArticles] = useState([]);

  // useEffect(() => {
  //   axios.get("/api/doubt").then((response) => {
  //     setDoubts(response.data);
  //   });
  // }, []);

  const { data, error, loading } = useFetch("/api/doubt");

  return (
    !loading &&
    !error && (
      <div className="doubts">
        <div className="doubts__header">
          <h1>پاسخ به شبهات </h1>
          <span className="doubts__header--icon"></span>
        </div>

        <div className="doubts__container">
          {data.map((doubt) => (
            <DoubtsCard key={doubt._id} {...doubt} />
          ))}
        </div>
      </div>
    )
  );
};

export default Doubts;
