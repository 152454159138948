import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";

import {
  IoPlayOutline,
  IoPlaySkipForwardOutline,
  IoPlaySkipBackOutline,
  IoVolumeHighOutline,
  IoPauseOutline,
  IoVolumeLowOutline,
  IoVolumeMuteOutline,
  IoVolumeMediumOutline,
} from "react-icons/io5";
import Slider from "@mui/material/Slider";
import "./podcastPlayer.scss";

const PodcastPlayer = () => {
  const { channelId } = useParams();

  const [voiceData, setVoiceData] = useState([]);
  const [selectedItem, setSelectedItem] = useState({ index: 0 });
  const podcastVoice = voiceData[selectedItem.index];
  const [playing, setPlaying] = useState(false);
  const [volume, setVolume] = useState(0.5);

  useEffect(() => {
    const fetchVoiceData = async () => {
      try {
        const response = await axios.get(`/api/voice`);
        setVoiceData(response.data);
        setSelectedItem({ index: 0 });
      } catch (error) {
        console.error("Error fetching voice data:", error);
      }
    };

    fetchVoiceData();
  }, [channelId]);

  const playingToggle = (playState) => {
    const player = document.getElementById("player");

    if (playState === "play") {
      if (player) {
        player.play();
        setPlaying(true);
      }
    } else {
      if (player) {
        player.pause();
        setPlaying(false);
      }
    }
  };

  const handlePrevious = () => {
    if (selectedItem.index - 1 < 0) {
      return;
    } else {
      playingToggle("pause");
      setSelectedItem({ index: selectedItem.index - 1 });
    }
  };

  const handleNext = () => {
    if (selectedItem.index + 1 > voiceData.length - 1) {
      return;
    } else {
      playingToggle("pause");
      setSelectedItem({ index: selectedItem.index + 1 });
    }
  };

  const handleVolume = (e) => {
    document.getElementById("player").volume = e.target.value;
    setVolume(e.target.value);
  };

  const decodedURL = podcastVoice
    ? `/api/${decodeURIComponent(podcastVoice.voice)}`
    : "";

  const decodedCover = decodeURIComponent(voiceData.podcast?.cover || "");
  const coverfile = `/api/${decodedCover}`;

  return (
    <div className="quran__container w-full h-screen py-12 px-0 flex justify-between flex-row-reverse">
      <div className="quran__container__playList h-full p-4 basis-[30%] bg-player-bg-dark flex justify-between flex-col overflow-y-scroll rounded-lg">
        {voiceData.map((podcast, index) => (
          <Link
            key={podcast.voiceId}
            to="#"
            className={
              index === selectedItem.index
                ? "quran__container__playList__item selected w-full mt-0 mb-2 mx-0 py-6 px-4 flex items-center rounded-lg no-underline"
                : "quran__container__playList__item w-full bg-player-surah-bg-light mt-0 mb-2 mx-0 py-6 px-4 flex items-center rounded-lg no-underline"
            }
            onClick={() => {
              playingToggle("pause");
              setSelectedItem({ index });
            }}
          >
            <p
              className={
                index === selectedItem.index
                  ? "quran__container__playList__item--number selectedNumber w-10 h-10 ml-4 rounded-lg flex justify-center items-center font-medium font-['Ubuntu',sans-serif]"
                  : "quran__container__playList__item--number w-10 h-10 ml-4 text-color-light bg-player-num-bg-grey rounded-lg flex justify-center items-center font-medium font-['Ubuntu',sans-serif]"
              }
            >
              {podcast.number}
            </p>
            <div className="quran__container__playList__item__details flex justify-between items-center flex-[1]">
              <h3 className="quran__container__playList__item__details--name pl-[.3rem] text-color-light font-normal font-['Noto_Naskh_Arabic',serif]">
                {podcast.title}
              </h3>
            </div>
          </Link>
        ))}
      </div>
      <div className="quran__container__playerContainer mt-auto  h-[100%] bg-player-bg-dark basis-[65%] rounded-lg flex flex-col ">
        <div className="quran__container__playerContainer__quran h-[80%] flex flex-col justify-between overflow-y-scroll items-center">
          {voiceData[selectedItem.index]?.cover && (
            <img
              src={`/api/${decodeURIComponent(
                voiceData[selectedItem.index]?.cover
              )}`}
              alt="Podcast Cover"
              className="w-full h-full rounded object-cover"
            />
          )}
        </div>
        <div className="quran__container__playerContainer__player h-[15%] p-10 flex justify-between items-center">
          <div className="quran__container__playerContainer__player__controls w-full m-0 py-[.8rem] px-6 bg-color-darkest rounded-lg flex justify-between items-center gap-4">
            <div className="quran__container__playerContainer__player__controls__buttons py-[.3rem] px-[.3rem] basis-[18%] text-color-light flex justify-around items-center cursor-pointer">
              <audio
                id="player"
                src={decodedURL}
                onEnded={() => setPlaying(false)}
                volume={volume}
              ></audio>
              <IoPlaySkipBackOutline
                onClick={handlePrevious}
                className="text-[1.5em]"
              />
              {playing ? (
                <IoPauseOutline
                  onClick={() => playingToggle("pause")}
                  className="text-[1.5em]"
                />
              ) : (
                <IoPlayOutline
                  className="text-[1.5em]"
                  onClick={() => playingToggle("play")}
                />
              )}
              <IoPlaySkipForwardOutline
                onClick={handleNext}
                className="text-[1.5em]"
              />
            </div>
            <p className="quran__container__playerContainer__player__controls__title text-color-light  py-[.3rem]  px-[.8rem] bg-player-bg-dark flex justify-between items-center gap-2">
              <span className="quran__container__playerContainer__player__controls__title--english   text-color-light font-['Ubuntu',sans-serif]">
                {voiceData[selectedItem.index]?.title}
              </span>
              -
              <span className="quran__container__playerContainer__player__controls__title--arabic   text-color-light font-['Noto_Naskh_Arabic',serif]">
                {voiceData[selectedItem.index]?.surahName}
              </span>
            </p>
            <div className="quran__container__playerContainer__player__controls__volume  py-[.1rem] px-2 text-color-light flex justify-between items-center gap-4 basis-[18%]">
              {(volume === 0 && (
                <IoVolumeMuteOutline className="text-[2em]" />
              )) ||
                (volume > 0 && volume < 0.3 && (
                  <IoVolumeLowOutline className="text-[2em]" />
                )) ||
                (volume >= 0.3 && volume < 0.7 && (
                  <IoVolumeMediumOutline className="text-[2em]" />
                )) ||
                (volume >= 0.6 && (
                  <IoVolumeHighOutline className="text-[2em]" />
                ))}
              <Slider
                sx={{
                  color: "white",
                  "& .MuiSlider-thumb": {
                    display: "none",
                  },
                }}
                value={volume}
                onChange={handleVolume}
                step={0.1}
                min={0}
                max={1}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PodcastPlayer;
