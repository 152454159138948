import React from "react";
import { toFarsiNumber } from "../../utils/utilityFunctions";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { Link } from "react-router-dom";
import { BsPerson } from "react-icons/bs";
import { FaEye } from "react-icons/fa";

import "./doubtsCard.scss";

const DoubtsCard = ({
  _id,
  cover,
  title,
  text,
  category,
  solver,
  solveDate,
}) => {
  const decodedCover = decodeURIComponent(cover);
  const coverfile = `/api/${decodedCover}`;

  return (
    <div className="doubtsCard">
      <Link to={`/doubt/${_id}`}>
        <div className="doubtsCard__image">
          <img src={coverfile} alt={"doubtsCard cover"} />
        </div>
      </Link>
      <div className="doubtsCard__box">
        <div className="doubtsCard__box__tags">
          <div className="doubtsCardTag">
            <span>{category}</span>
          </div>
        </div>
        <div className="articleCard__box__title">
          <Link to={`/article/${_id}`}>
            <span>{title}</span>
          </Link>
        </div>

        <div className="doubtsCard__footer">
          <div className="doubtsCard__footer__top">
            <div className="doubtsCard__footer__top__author">
              <span>{solver}</span>
              <span> :نویسنده </span>
              <BsPerson className="doubtsCard__footer__top__author--icon" />
            </div>

            <div className="doubtsCard__footer__top__view">
              <span>{toFarsiNumber(solveDate)}</span>
              <DateRangeIcon
                style={{ fontSize: "0.8em" }}
                className="doubtsCard__footer__top__author--icon"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoubtsCard;
