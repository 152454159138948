import React, { useEffect, useState } from "react";
import axios from "axios";

import "./serial.scss";

import { toFarsiNumber } from "../../utils/utilityFunctions";
import { HiOutlineBriefcase } from "react-icons/hi";
import { FaRegCalendarAlt } from "react-icons/fa";
import { HiOutlineClock } from "react-icons/hi";
import { BsCameraVideo } from "react-icons/bs";
import { BsShieldCheck } from "react-icons/bs";
import { BsInfoSquare } from "react-icons/bs";
import { BsPeople } from "react-icons/bs";
import { Link, useParams } from "react-router-dom";
import CourseInfoBox from "../../Components/courseComponents/courseInfoBox/CourseInfoBox";
import SerialSidebar from "../../Components/serialSidebar/SerialSidebar";
import CourseDescription from "../../Components/courseComponents/courseDescription/CourseDescription";
import SerialContent from "../../Components/serialContent/SerialContent";

const Serial = () => {
  const [serialData, setSerialData] = useState();
  const { id } = useParams();

  useEffect(() => {
    axios
      .get(`/api/seriesData/${id}`)
      .then((response) => {
        setSerialData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching course data:", error);
      });
  }, [id]);

  //   useEffect(() => {
  //     window.scrollTo(0, 0);
  //   }, []);

  //   const lastUpdate = `${toFarsiNumber(1402)}/ ${toFarsiNumber(
  //     6
  //   )} / ${toFarsiNumber(2)} `;

  //   const courseDuration = ` ساعت ${toFarsiNumber(54)}`;

  const decodedPoster = decodeURIComponent(serialData?.poster || "");
  const posterfile = `/api/${decodedPoster}`;

  return (
    <div className="serial">
      <div className="serial__header">
        <div className="serial__header__cover">
          <img src={posterfile} alt="serial cover" />
        </div>
        <div className="serial__header__detail">
          <div className="serial__header__detail__text">
            <h2 className="serial__header__detail__text__title">
              {serialData?.title || "Loading..."}
            </h2>
            <p className="serial__header__detail__text__description">
              {serialData?.quickDescription || "Loading..."}
            </p>
          </div>
          <div className="serial__header__detail__signUp">
            {/* <div className="serial__header__detail__signUp__price">
              <span>{toFarsiNumber(4350000)}</span>
            </div> */}
            <Link to={`/subscription`}>
              <div className="serial__header__detail__signUp--btn">
                <span>خرید اشتراک</span>
                <BsShieldCheck />
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="serial__container">
        <SerialSidebar serialId={id} />
        <div className="serial__container__main">
          <div className="serial__container__main__info">
            <CourseInfoBox
              icon={<BsPeople fontSize="2.5rem" />}
              title="کارگردان"
              detail={serialData?.director || "Loading..."}
            />
            <CourseInfoBox
              title="نویسنده"
              icon={<FaRegCalendarAlt fontSize="2.5rem" />}
              detail={serialData?.writer || "Loading..."}
            />
            <CourseInfoBox
              icon={<HiOutlineClock fontSize="2.5rem" />}
              title=" مدت زمان فیلم"
              detail={serialData?.duration || "Loading..."}
            />
            <CourseInfoBox
              icon={<BsInfoSquare fontSize="2.5rem" />}
              title="سال تولید"
              detail={serialData?.year || "Loading..."}
            />
            <CourseInfoBox
              icon={<BsCameraVideo fontSize="2.5rem" />}
              title="ساخت کشور"
              detail={serialData?.country || "Loading..."}
            />
            <CourseInfoBox
              icon={<HiOutlineBriefcase fontSize="2.5rem" />}
              title=" IMDB"
              detail={serialData?.imdbScore || "Loading..."}
            />
          </div>
          <CourseDescription
            description={serialData?.shortStory || "Loading..."}
          />
          <SerialContent seasonss={serialData?.seasons || []} />
        </div>
      </div>
    </div>
  );
};

export default Serial;
