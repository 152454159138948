import React from "react";
import { TiTick } from "react-icons/ti";

import SubscriptionCard from "../subscriptionCard/SubscriptionCard";
import "./subscription.scss";

const Subscription = () => {
  return (
    <div className="profile__main__leftContainer__left subscription">
      <div className="subscription__header">
        <h1 className="subscription__header--title">خرید اشتراک</h1>
      </div>
      <hr />
      <div className="subscription__body">
        <SubscriptionCard wallet={"wallet1"} months={"‍۱"} />
        <SubscriptionCard wallet={"wallet2"} months={"۳"} />
        <SubscriptionCard wallet={"wallet3"} months={"۶"} />
        <SubscriptionCard wallet={"wallet2"} months={"رایگان"} />
      </div>
      <p className="subscription__footnote1">
        <TiTick />
        به تمامی مبالغ ۹% مالیات بر ارزش افزوده اضافه خواهد شد
      </p>
      <p className="subscription__footnote2">
        <TiTick />
        ترافیک مصرفی بر اساس سیاست سرویس دهنده اینترنت شما به صورت تمام بها یا
        نیم بها محاسبه خواهد شد و آریو تی وی دراین خصوص برای کاربران خود تعهدی
        نخواهد داشت.
      </p>
    </div>
  );
};

export default Subscription;
