import axios from "axios";

const getSurahs = async () => {
  const response = await axios.get("http://api.alquran.cloud/v1/surah");
  return response.data;
};

const getJuzs = async () => {
  const response = await axios.get("https://api.quran.com/api/v4/juzs");
  return response.data;
};

const getSurahsByJuz = async (selectedJuz) => {
  const response = await axios.get(
    `http://api.alquran.cloud/v1/juz/${selectedJuz}`
  );

  return response.data;
};

const quranService = {
  getSurahs,
  getJuzs,
  getSurahsByJuz,
};

export default quranService;
