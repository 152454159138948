// import axios from "axios";
import React, { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import MadahTopicSection from "../madahTopicSection/MadahTopicSection";

import "./madahTopic.scss";

const MadahTopic = ({ title, voice }) => {
  const [activeStatus, setActiveStatus] = useState(false);

  const handleHeaderClick = async () => {
    setActiveStatus(!activeStatus);
  };

  return (
    <div className="madahTopic">
      <div
        className={
          activeStatus
            ? "madahTopic__header madahTopic__header--active"
            : "madahTopic__header"
        }
        onClick={handleHeaderClick}
      >
        <div className="madahTopic__header--icon">
          {activeStatus ? <IoIosArrowUp /> : <IoIosArrowDown />}
        </div>
        <span className="madahTopic__header--title">{title}</span>
      </div>
      <div
        className="madahTopic__main"
        style={activeStatus ? { maxHeight: "100%" } : { maxHeight: "0" }}
      >
        {voice.map((voices) => (
          <MadahTopicSection
            key={voices._id}
            {...voices}
            id={voices._id}
            voice={voices}
          />
        ))}
      </div>
    </div>
  );
};

export default MadahTopic;
