import React from "react";
import "./madahiCard.scss";
import { toFarsiNumber } from "../../utils/utilityFunctions";
import { Link } from "react-router-dom";

const MadahiCard = ({ name, profilePic, _id, bio }) => {
  const decodedCover = decodeURIComponent(profilePic);
  const coverfile = `/api/${decodedCover}`;

  return (
    <div className="madahiCard">
      <div className="madahiCard__image">
        <Link to={`/madah/${_id}`}>
          <img src={coverfile} alt={"cover img"} />
          {/* <span className="madahiCard__button">Play</span> */}
        </Link>
      </div>
      <div className="madahiCard__title">
        <span>{name}</span>
      </div>
      <div className="madahiCard__detail">
        <span className="madahiCard__detail__date">
          <span>{bio.split(" ").slice(0, 10).join(" ")}...</span>
          {/* <span>تیر</span> */}
          {/* <span>{toFarsiNumber(31)}</span>
        </span>
        <span className="madahiCard__detail__time">
          <span>{toFarsiNumber(24)}</span>
          <span> دقیقه</span> */}
        </span>
      </div>
    </div>
  );
};

export default MadahiCard;
