import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./terms.scss";

const Terms = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="terms__container">
        <div className="terms__content">
          <p className="terms__content--title">
            قوانین و مقررات استفاده از پلتفرم آریو (رسانه جامع فیلم و سریال)
          </p>
          <p className="terms__content--p">
            کاربر گرامی؛ ضمن سپاس از انتخاب سرویس آریو لازم است پیش از خرید مورد
            نظر و تماشای فیلم های ورزشی، رویداد ها، توافقنامه و قوانین ذیل را
            مطالعه فرمایید، آریو سرویس خود را تحت شرایط و مقررات این توافقنامه
            در اختیار شما می‌گذارد و شما به‌عنوان کاربران این سرویس ملزم به
            رعایت مفاد مذکور در این توافقنامه که ممکن است در آینده تغییر یابد،
            هستید.
          </p>
          <p className="terms__content--p">
            این سرویس با نام تجاری " آریو " ثبت گردیده است و از حق کپی‌رایت
            برخوردار است. اگر در سایت‌های دیگر با موارد نقض کپی‌رایت آریو مواجه
            شدید، لطفاً آن را به سرویس پشتیبانی « آریو » به آدرس ایمیل فوق :{" "}
            <a
              className="terms__content--a"
              href="http://info@ariotv.com"
              target="_blank"
              style={{ color: "#3cc328" }}
            >
              info@ario.ir
            </a>{" "}
            اطلاع دهید.{" "}
          </p>
          <p className="terms__content--p">
            تمامی محتواهای ارائه شده در سرویس آریو در چارچوب قوانین و مقررات
            جمهوری اسلامی ایران است.
          </p>
          <p className="terms__content--p">
            برای دسترسی به نمایش محتوای مورد نظر، کاربر باید اقدام به خرید
            اشتراک نماید. خرید اشتراک در واقع به منزله خرید دسترسی به تماشای
            آنلاین و یا محتواهای آریو است.
          </p>
          <p className="terms__content--p">
            خرید اشتراک صرفاً جهت استفاده از سرویس آریو است و هزینه اینترنت را
            شامل نمی‌شود.
          </p>
          <p className="terms__content--p">
            آریو در صورت مشاهده هرگونه اقدام غیرمتعارف برای دسترسی به اشتراک،
            خرید و ورود، این حق را دارد که این اقدامات غیرمجاز را متوقف و در
            ادامه اشتراک کاربر را لغو کند.
          </p>
          <p className="terms__content--p">
            اگر حساب کاربری و رمز عبور در اختیار شخص دیگری قرار بگیرد، مسئولیت
            آن بر عهدهٔ صاحب حساب کاربری خواهد بود – همچنین اگر حساب کاربری در
            اختیار افراد زیادی قرار بگیرد، آریو این اختیار را دارد که در هنگام
            ورود به‌حساب کاربری محدودیت‌هایی را (طبق صلاحدید خود) اعمال نماید.
            هر کاربر 2 بار امکان ورود با رمز را در هر 24 ساعت دارد و در صورتی که
            بیشتر از 2 بار با رمز وارد حساب کاربری شود، دفعات بعدی باید علاوه بر
            رمز، کد ارسال شده را هم وارد نماید. کاربر عزیز همچنین در فواصل مشخص
            باید حداقل یک‌بار با کد وارد حساب کاربری خود شوید تا اطمینان حاصل
            شود که به شماره همراه ارائه شده در زمان ثبت‌نام دسترسی دارید و مالک
            شماره همراه اطلاع دارد.
          </p>
          <p className="terms__content--p">
            تعداد محتواهایی که کاربران با خرید اشتراک به آنها دسترسی پیدا
            می‌کنند ممکن است پیش از پایان مدت اعتبار اشتراک، تغییر یافته و به
            مجموع محتواها اضافه یا از آن حذف گردد. همچنین ممکن است با تغییر مکان
            جغرافیایی خود به سایر کشورها، دسترسی به تعدادی از محتواها که اجازه
            نشر در خارج از کشور ندارند را از دست بدهید.
          </p>
          <p className="terms__content--p">
            تعداد محتواهایی که کاربران با خرید اشتراک به آنها دسترسی پیدا
            می‌کنند، ممکن است پیش از پایان مدت اعتبار اشتراک، تغییر یافته و فیلم
            به مجموع محتواها اضافه یا از آن حذف شود. مشاهده تمامی محتوای موجود
            در سایت آریو تنها از طریق IPهای داخل ایران امکان‌پذیر خواهد بود.
          </p>
          <p className="terms__content--p">
            اعمال غیرقانونی و مغایر با قوانین موضوعه جمهوری اسلامی ایران به هر
            نحو ممکن ممنوع است.
          </p>
          <p className="terms__content--p">
            استفاده از سرویس آریو هیچگونه حقی را برای کاربران در ارتباط با
            مالکیت فیلم ها به طور کلی هر محتوای ارائه شده و انتشار یا پخش عمومی
            آنها چه از طریق سرویس آریو و چه از طرق دیگر ایجاد نمی کند. مسلم است
            با پرداخت مبلغ، شما می توانید خدمات خریداری شده را در بازه زمانی
            مشخص شده مشاهده کنید، اما مالک این محتوا نخواهید شد و اجازه دانلود
            (به استثنای دانلود محافظت شده اپلیکیشن آریو و بازنشر آن را ندارید.
          </p>
          <p className="terms__content--p">
            آریو این حق را دارد که در صورت نیاز تغییراتی در قوانین ایجاد کرده یا
            مانع دسترسی به بعضی از قسمت‌های سرویس یا همه سرویس به طور موقت یا
            دائمی شود.
          </p>
          <p className="terms__content--p">
            آریو این حق را دارد در صورت صلاحدید به‌منظور کاهش هزینه‌ها و جلوگیری
            از افزایش قیمت اشتراک‌ها در هر بخش از محتوا، نسبت به پخش تبلیغات
            اقدام نماید.
          </p>
          <p className="terms__content--p">
            آریو با داشتن تنوع محتوایی این اختیار را به کاربر داده است تا خود
            طبق علایق و سلایق شخصی و اجتماعی خود، فیلم مورد نظر خود را تماشا
            نماید، ما هیچ تضمینی بر اعتبار آنها و تأثیر مثبت یا منفی آنها بر
            مخاطب نخواهیم داشت.
          </p>
          <p className="terms__content--p">
            ممکن است در آینده تغییراتی توسط مدیریت آریو در این توافقنامه ایجاد
            شود که شامل نه محدود به حذف یا اضافه‌کردن مطالب و قوانینی به آن است
            که بلافاصله از نظر مسئولان سرویس لازم الاجراست؛ لذا کاربر موظف
            به‌مرور قوانین طی استفاده از سرویس آریو است تا از تغییرات احتمالی آن
            آگاه گردد. این تغییرات در شبکه‌های اجتماعی آریو به اطلاع کاربران
            خواهد رسید.
          </p>
          <p className="terms__content--p">
            در قسمت مربوط به نظرات کاربران آریو ، انتشار مطالب خلاف یا نقض کننده
            قوانین جمهوری اسلامی ایران، مضر، تهدیدکننده، توهین‌آمیز، غیراخلاقی،
            افترا آمیز و مبتذل و همچنین مطالبی که به نژاد، گروه یا دسته خاصی از
            مردم توهین کند، ممنوع است در قسمت مربوط به نظرات کاربران، قرار دادن
            لینک‌هایی به سایت‌های غیرقانونی، غیراخلاقی یا مبتذل و همین‌طور
            سایت‌هایی که به هر نحو مناسبات فرهنگی، اخلاقی، عرفی یا قانونی جامعه
            را زیر پا می‌گذارند یا با قوانین جمهوری اسلامی ایران مغایرند، ممنوع
            است.
          </p>
          <p className="terms__content--p">
            هر کاربر در رسانه آریو حق ایجاد یک نام کاربری را دارد و ایجاد چند
            نام کاربری برای یک نفر جهت استفاده بیشتر از تسهیلاتی که برخی اوقات
            توسط آریو در اختیار کاربران قرار می‌گیرد، ممنوع است. همچنین کاربران
            آریو حق واگذاری نام کاربری خود به فرد دیگر و یا استفاده از نام
            کاربری فرد دیگر را ندارند
          </p>
          <p className="terms__content--p">
            محتواهای موجود توسط یک تهیه‌کننده یا شرکت تولیدی فیلم تهیه شده است.
            آریو تنها یک بستر نمایش این محتواها بوده و نقشی در تولید آنها
            نداشته، از این رو مسئولیتی در قبال محتوای ارائه شده ندارد.
          </p>
          <p className="terms__content--p">
            در صورت بروز شرایط فورس ماژور (مانند سیل، زلزله، بلایای طبیعی و...)
            و پیش آمدهای خارج از کنترل آریو که منجر به قطع سرویس و ارائه خدمات
            شود، تا زمان برطرف شدن مشکل، این قرارداد معلق خواهد شد.
          </p>
          <p className="terms__content--p">
            این توافقنامه مشروعیت و اعتبار خود را از قوانین حاکم بر جمهوری
            اسلامی ایران کسب می‌کند.
          </p>
          <p className="terms__content--p">
            ترافیک مصرفی کاربران آریو ، بر اساس سیاست‌ها و تنظیمات اپراتورهای
            مختلف اینترنتی به‌صورت تمام‌بها یا نیم‌بها محاسبه خواهد شد و آریو
            تضمینی برای محاسبه ترافیک نیم‌بها برای کاربران خود نخواهد داشت.
          </p>
          <p className="terms__content--p">
            در شرایطی که هم‌زمان با تماشای آریو ، برنامه‌هایی نظیر فیلترشکن،
            تلگرام، مرورگر و یا سایر نرم‌افزارهای موجود در تلویزیون هوشمند، تبلت
            و گوشی تلفن همراه شما باز یا در حال دانلودکردن باشند، به طور طبیعی
            از حجم اینترنت شما کسر خواهد شد و این حجم مصرفی هیچ ارتباطی با
            استفاده از سرویس آریو نخواهد داشت.
          </p>
          <br />
          <p className="terms__content--title">قوانین اکران آنلاین آریو :</p>
          <p className="terms__content--p">
            مدت‌زمان دسترسی به فیلم‌های اکران انلاین خریداری شده در بخش اکران
            آنلاین تنها همزمان می باشد و از زمان خرید اشتراک بلیط تماشا انلاین
            خواهد بود و کاربران در این مدت می‌توانند فیلم خریداری شده را تماشا
            کنند. پس از اتمام این مدت، فیلم اکران شده از دسترسی کاربران گرامی
            خارج خواهد شد.
          </p>
          <p className="terms__content--p">
            در هر لحظه فقط یک نفر امکان تماشای فیلم اکران آنلاین را دارد و امکان
            تماشای هم‌زمان وجود ندارد. در صورت تماشای هم‌زمان فیلم، برای هر دو
            کاربر پیغام خطا نمایش داده می‌شود.
          </p>
          <p className="terms__content--p">
            هزینهٔ تماشای فیلم‌های اکران آنلاین، در صفحه مربوط به هر فیلم
            قابل‌مشاهده است.
          </p>
          <p className="terms__content--p">
            امکان دانلود و یا ضبط برای فیلم‌های این بخش وجود ندارد و انجام این
            امور ممنوع می باشد.
          </p>
          <br />
          <p className="terms__content--title">
            قوانین سرویس پرداخت با تمدید خودکار:
          </p>
          <p className="terms__content--p">
            جهت تماشا رویداد ها مبلغ خرید اشتراک در آریو (مندرج در صفحه خرید
            اشتراک) از حساب شما پرداخت می‌شود و هر زمان بخواهید می‌توانید این
            تمدید خودکار را متوقف کنید.
          </p>
          <p className="terms__content--p">
            آریو حق برداشت بیش از مبلغ اشتراک مندرج در صفحه تمدید خودکار در هر
            ماه از حساب بانکی کاربر را ندارد، این مبلغ در هنگام ثبت قرارداد به
            اطلاع کاربر رسانده می‌شود.
          </p>
          <p className="terms__content--p">
            کاربر در صورت تمایل، امکان لغو قرارداد خود را دارد و پس از لغو
            قرارداد، برداشتی از حساب ایشان توسط آریو صورت نخواهد گرفت.
          </p>
          <p className="terms__content--p">
            کاربر تا هفت روز پس از لغو سرویس تمدید خودکار خود، امکان فعال‌سازی
            مجدد این سرویس را نخواهد داشت.
          </p>
          <p className="terms__content--p">
            امکان حذف اکانت در آریو وجود ندارد ولی امکان غیرفعال‌سازی اکانت توسط
            خود کاربر وجود دارد.
          </p>
          <p className="terms__content--p">
            امکان انتقال اشتراک از یک کاربری به کاربری دیگر وجود ندارد.
          </p>
          <p className="terms__content--p">
            در هر خرید امکان استفاده از بیش از یک تخفیف وجود ندارد. میزان تخفیف
            نهایی به‌صورت هوشمند توسط سیستم محاسبه می‌شود و برای کاربرهای مختلف
            متفاوت خواهد بود.
          </p>
          <p className="terms__content--p">
            جهت تماشای رویداد ها در آریو باید دستگاه حداقل سخت‌افزار و نرم‌افزار
            مورد را نیاز را داشته باشید. طبیعی است تلویزیون‌های قدیمی و
            سیستم‌های قدیمی امکان استفاده از تمام امکانات آریو را ندارند.
          </p>
          <p className="terms__content--p">
            امکان عودت مبلغ اشتراک خریده شده در آریو وجود ندارد.
          </p>
          <br />
          <p className="terms__content--title">حریم خصوصی کاربران در آریو</p>
          <p className="terms__content--p">
            در این بخش به کاربران توضیح خواهیم داد که از ایشان چه اطلاعاتی
            جمع‌آوری می‌شود و آریو با این اطلاعات چگونه برخورد خواهد کرد. شایان
            ذکر است که آریو همواره اصول محرمانگی را در مورد اطلاعات شخصی کاربر
            را رعایت کرده و از این اطلاعات به نحو احسن محافظت می‌نماید. آریو
            اطلاعات شخصی کاربران را در اختیار اشخاص ثالث قرار نخواهد داد. البته
            به‌استثنای مواردی که به‌موجب قانون و با دستور مقامات قضایی و امنیتی
            موظف به انجام این کار باشد. چنانچه این اطلاعات به‌صورت غیرعمد یا
            بدون اطلاع و رضایت ما در دسترس شخص ثالثی قرار گیرد، آریو مسئولیتی
            نخواهد داشت
          </p>
          <p className="terms__content--p">
            اطلاعاتی که شما در اختیار آریو قرار می‌دهید بدین‌گونه است که در
            فرآیند ثبت‌نام، اطلاعات مربوط به کاربر شامل نام و نام خانوادگی، آدرس
            ایمیل و شماره تلفن همراه دریافت می‌گردد و در پایان پیامک کد جهت
            احراز هویت کاربر به شماره تماس اعلام شده از طرف وی فرستاده می‌شود.
            در صورت ثبت‌نام، با استفاده از نام کاربری در آریو اطلاعاتی از قبیل
            "رویداد ها و..."، "ویرایش مشخصات"، "فیلم‌های دیده شده" و "سوابق
            پرداخت" نمایش داده می‌شوند. در قسمت ویرایش مشخصات، کاربر قادر است تا
            در صورت تمایل، اطلاعاتی نظیر "نام (به فارسی) "، "شماره تلفن همراه"،
            "نام کاربری" و "ایمیل" را به‌روزرسانی کند.
          </p>
          <p className="terms__content--p">
            آریو اطلاعاتی که از حساب کاربران به طور خودکار بدست می‌آورد جهت
            تحلیل، بررسی و شخصی‌سازی خدمات خود و ارائه پیشنهادات ویژهٔ نزدیک‌تر
            به سلیقه کاربران استفاده خواهد کرد. این اطلاعات به اسپرت پلاس کمک
            می‌کند تا سرویس خود را بهبود بخشیده و محتوای مرتبط‌تری با علایق هر
            کاربر به ایشان ارائه کند.
          </p>
          <p className="terms__content--p">
            آریو این اختیار را دارد، هنگام پخش فیلم و رویداد ها به فراخور زمان و
            محتوا، برای کاربر تبلیغ نمایش دهد.
          </p>
          <p className="terms__content--p">
            آریو جهت ارائه بهتر سرویس خود و اطلاع‌رسانی در خصوص طرح‌های ویژه و
            پیشنهاد تماشای فیلم ها و رویداد های جدید و ... ممکن است از طریق
            ایمیل یا شماره تلفن همراه ثبت شده، اقدام به ارسال اطلاعاتی برای
            کاربران نماید. شما همواره می‌توانید با کلیک کردن بر روی دکمه لغو
            اشتراک در انتهای ایمیل‌های اطلاع‌رسانی آنها را غیرفعال کنید.
          </p>
          <p className="terms__content--p">
            لازم به ذکر است پلتفرم آریو برای سرویس‌دهی در داخل ایران طراحی شده
            است و به‌منظور استفاده از آن می‌بایست کاربران از ارائه‌دهندگان مجاز
            اینترنت مستقر در ایران جهت اتصال به آریو استفاده نمایند.
          </p>
          <p className="terms__content--p">
            به بیان دیگر با توجه به موارد فنی و حقوقی کاربران نمی‌توانند با
            استفاده از vpn، فیلترشکن و یا هر روش دیگری از خارج کشور اقدام به
            استفاده از آریو نمایند.
          </p>
          <p className="terms__content--p">
            به بیان دیگر با توجه به موارد فنی و حقوقی کاربران نمی‌توانند با
            استفاده از vpn، فیلترشکن و یا هر روش دیگری از خارج کشور اقدام به
            استفاده از آریو نمایند.
          </p>
          <p className="terms__content--p">
            درصورتیکه پس از خواندن این متن همچنان سوال یا ابهامی داشتید،
            میتوانید با ما از طریق ایمیل{" "}
            <a
              href="http://info@ariotv.com"
              target="_blank"
              style={{ color: "#3cc328" }}
            >
              info@ario.ir
            </a>{" "}
            یا شماره تماس 021-26761496 هفت روز هفته به صورت 24 ساعته، حتی در
            روزهای تعطیل ارتباط برقرار کنید.
          </p>
          <br />
          <p className="terms__content--title">
            شرایط ادامه خدمات و شیوه نامه حریم خصوصی درحریم خصوصی کاربران در
            آریو
          </p>
          <p className="terms__content--p">
            شما باید از کلیۀ خط مشی های اسپورت پلاس پیروی کنید. از سرویس های
            اسپورت پلاس سوء استفاده نکنید. مثلاً از تداخل در سرویس های اسپورت
            پلاس یا تلاش برای دسترسی به آن ها از روشی جز رابط کاربری و
            دستورالعمل های اسپورت پلاس خودداری کنید. از سرویس های اسپورت پلاس
            فقط به روش مجاز شناخته شدۀ قانونی، از جمله قانون تجارت الکترونیک
            ایران مصوب 1382، استفاده کنید. اگر از شرایط یا خط مشی های اسپورت
            پلاس تخطی کنید و یا هر گونه عملکرد مشکوک داشته باشید، ممکن است ارائۀ
            سرویس‌ها به شما را متوقف کنیم یا به حالت تعلیق درآوریم.
          </p>
        </div>
      </div>
    </>
  );
};
export default Terms;
