import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import quranService from "./quranService";

const initialState = {
  surahs: [],
  juzs: [],
  surahsByJuz: [],
  specificSurah: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getSurahs = createAsyncThunk("surahs/getAll", async (thunkAPI) => {
  try {
    return await quranService.getSurahs();
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const getSurahsByJuz = createAsyncThunk(
  "surahs/getbyJuz",
  async (selectedJuz, thunkAPI) => {
    try {
      return await quranService.getSurahsByJuz(selectedJuz);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllJuzs = createAsyncThunk("juzs/getAll", async (thunkAPI) => {
  try {
    return await quranService.getJuzs();
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const quranSlice = createSlice({
  name: "quran",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSurahs.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSurahs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.surahs = action.payload;
      })
      .addCase(getSurahs.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getSurahsByJuz.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSurahsByJuz.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.surahsByJuz = action.payload;
      })
      .addCase(getSurahsByJuz.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getAllJuzs.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllJuzs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.juzs = action.payload;
      })
      .addCase(getAllJuzs.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = quranSlice.actions;
export default quranSlice.reducer;
