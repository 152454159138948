// import axios from "axios";
import React, { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import CourseTopicSection from "../courseTopicSection/courseTopicSection";

import "./courseTopic.scss";

const CourseTopic = ({ title, lectures }) => {
  const [activeStatus, setActiveStatus] = useState(false);

  const handleHeaderClick = async () => {
    setActiveStatus(!activeStatus);
  };

  return (
    <div className="courseTopic">
      <div
        className={
          activeStatus
            ? "courseTopic__header courseTopic__header--active"
            : "courseTopic__header"
        }
        onClick={handleHeaderClick}
      >
        <div className="courseTopic__header--icon">
          {activeStatus ? <IoIosArrowUp /> : <IoIosArrowDown />}
        </div>
        <span className="courseTopic__header--title">{title}</span>
      </div>
      <div
        className="courseTopic__main"
        style={activeStatus ? { maxHeight: "100%" } : { maxHeight: "0" }}
      >
        {lectures.map((lecture) => (
          <CourseTopicSection
            key={lecture._id}
            {...lecture}
            id={lecture._id}
            lecture={lecture}
          />
        ))}
      </div>
    </div>
  );
};

export default CourseTopic;
