import React, { useState } from "react";
import "./madahDescription.scss";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

const MadahDescription = ({ description }) => {
  const [fullContent, setFullContent] = useState(false);

  return (
    <div className="madahDescription">
      <div className="madahDescription--title">
        <span>متن مداحی</span>
      </div>
      <div className="madahDescription__detail">
        <div
          className="madahDescription__detail--content"
          style={fullContent ? { maxHeight: "100%" } : { maxHeight: "200px" }}
        >
          <p>{description}</p>
        </div>
        <div
          className={fullContent ? " " : "madahDescription__detail--shadow"}
        ></div>
      </div>
      <div className="madahDescription__showMore">
        <button
          className="madahDescription__showMore--btn"
          onClick={() => {
            setFullContent(!fullContent);
          }}
        >
          {fullContent ? <IoIosArrowUp /> : <IoIosArrowDown />}
          <span>{fullContent ? "مشاهده کمتر" : "مشاهده بیشتر"}</span>
        </button>
      </div>
    </div>
  );
};

export default MadahDescription;
