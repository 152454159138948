import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;
const token = localStorage.getItem("Token");

export const publicRequest = axios.create({
  baseURL: baseUrl,
  withCredentials: true,
  headers: {
    authorization: `Beare ${token}`,
  },
});
