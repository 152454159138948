import React, { useEffect, useState } from "react";
import { toFarsiNumber } from "../../../utils/utilityFunctions";
import CourseTopic from "../courseTopic/CourseTopic";

import "./courseContent.scss";

const CourseContent = ({ sessions }) => {
  useEffect(() => {}, []);

  return (
    <div className="courseContent">
      <div className="courseContent__header">
        <p>{/* {toFarsiNumber(54)}:{toFarsiNumber(30)} */}</p>
        <h2>سر فصل های دوره</h2>
      </div>
      {sessions.map((session) => (
        <CourseTopic key={session._id} {...session} />
      ))}
    </div>
  );
};

export default CourseContent;
