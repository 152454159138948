import React from "react";
import "./courseInfoBox.scss";
import { toFarsiNumber } from "../../../utils/utilityFunctions";

const CourseInfoBox = ({ icon, title, detail }) => {
  return (
    <div className="courseInfoBox">
      <div className="courseInfoBox__text">
        <span>{title}</span>
        <p>{toFarsiNumber(detail)}</p>
      </div>
      <div className="courseInfoBox__icon">{icon}</div>
    </div>
  );
};

export default CourseInfoBox;
