import React from "react";
import { Link } from "react-router-dom";
import { FiPlayCircle } from "react-icons/fi";
import { toFarsiNumber } from "../../utils/utilityFunctions";

import "./serialTopicSection.scss";

const SerialTopicSection = ({ video, title, episode, id }) => {
  const numberOfEoisode = episode?.length || 0;

  return (
    <div className="serialTopicSection">
      <Link
        to={`/videoPlay/${id}`}
        className="serialTopicSection__main"
        state={{ video, title }}
      >
        <h4 className="serialTopicSection__main--title">{title}</h4>
        <span className="serialTopicSection__main--id">
          {toFarsiNumber(12)}
        </span>
      </Link>
      <div className="serialTopicSection__detail">
        <div className="serialTopicSection__detail__duration">
          <FiPlayCircle className="serialTopicSection__detail__duration--icon" />
          <span className="serialTopicSection__detail__duration--time">
            {/* {toFarsiNumber(10)}:{toFarsiNumber(45)} */}
          </span>
        </div>
        <span className="serialTopicSection__detail--status">رایگان</span>
      </div>
    </div>
  );
};
export default SerialTopicSection;
