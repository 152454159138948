import React, { useEffect, useState } from "react";
import MadahTopic from "../madahTopic/MadahTopic";

import "./madahContent.scss";

const MadahContent = ({ occasion }) => {
  useEffect(() => {}, []);

  return (
    <div className="madahContent">
      <div className="madahContent__header">
        {/* <p>
          {toFarsiNumber(54)}:{toFarsiNumber(30)}
        </p> */}
        <h2>مناسبت های مداحی</h2>
      </div>
      {occasion.map((occasion) => (
        <MadahTopic key={occasion._id} {...occasion} />
      ))}
    </div>
  );
};

export default MadahContent;
