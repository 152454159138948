import React from "react";
import "./articleFooter.scss";
import { IoMdFlag } from "react-icons/io";

export default function ArticleFooter({ writer }) {
  return (
    <div className="article__header">
      <h2> نویسنده : {writer}</h2>
      <div className="article__header">
        <p className="article__header--report">
          گزارش تخلف
          <IoMdFlag size={24} style={{ marginTop: "-0.3rem" }} />
        </p>
      </div>
    </div>
  );
}
