import axios from "axios";

const getDoubts = async () => {
  const response = await axios.get("/api/doubts");
  return response.data;
};

const doubtsService = {
  getDoubts,
};

export default doubtsService;
