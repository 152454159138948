import React, { useEffect, useState } from "react";
import axios from "axios";
import PodcastPlayer from "../../Components/podcastPlayer/PodcastPlayer";

import "./podcastEpisode.scss";
import CommentBox from "../../Components/commentBox/CommentBox";

export default function PodcastEpisode() {
  const [voiceData, setVoiceData] = useState([]);

  useEffect(() => {
    axios
      .get("/api/voice")
      .then((response) => {
        setVoiceData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching voice data:", error);
      });
  }, []);

  // const decodedCover = decodeURIComponent(voiceData?.cover || "");
  // const coverfile = `/api/${decodedCover}`;

  return (
    <div className="home__player w-full pt-8 px-12 pb-16 bg-color-darkest">
      <h1 className="home--title text-color-light text-[2em] pt-4 pb-8 px-0">
        پخش کننده پادکست
      </h1>

      {/* <div className="course__header__cover">
        <img src={coverfile} alt="course cover" />
      </div> */}

      <PodcastPlayer voiceData={voiceData} />
      {/* <CommentBox review={voiceData?.review || []} /> */}
    </div>
  );
}
