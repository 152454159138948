import React from "react";
import "./articleHeader.scss";

const ArticleHeader = ({ headerTitle }) => {
  return (
    <div className="articleHeader">
      <h2>{headerTitle}</h2>
    </div>
  );
};

export default ArticleHeader;
