import React, { useEffect } from "react";
import "./courseList.scss";

import CourseBoxHeader from "../../Components/courseComponents/courseBoxHeader/CourseBoxHeader";
import CourseContainer from "../../Components/courseComponents/courseContainer/CourseContainer";
import CourseSlider from "../../Components/courseComponents/courseSlider/CourseSlider";
import { useDispatch, useSelector } from "react-redux";
import { getCourses } from "../../reducers/course/courseSlice";

const CourseList = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCourses());
  }, []);

  const {
    courses: { courses },
  } = useSelector((state) => state);

  return (
    <>
      <div className="courseList">
        <div className="courseList__recentCourses">
          <CourseBoxHeader
            headerTitle="جدیدترین دوره ها "
            headerInfo="جدیدترین دوره های آموزش قرآن"
            courseName="qoran"
          />
          <CourseSlider />
        </div>
        <div className="courseList__lastCourses">
          <CourseBoxHeader
            headerTitle="آخرین دوره ها"
            headerInfo="آخرین دوره"
            courseName="namaz"
          />
          <CourseContainer />
        </div>
      </div>
    </>
  );
};

export default CourseList;
