import React from "react";
import "./subscriptionCard.scss";

const SubscriptionCard = ({ wallet, months }) => {
  return (
    <div className={`subscriptionCard ${wallet}`}>
      <div className="circle">
        <div className="overlay"></div>
        <div className="circle__text">
          <h2>{months}</h2>
          <p>ماهه</p>
        </div>
      </div>
      <p className="text">اشتراک</p>
      <button className="btn">خرید</button>
    </div>
  );
};

export default SubscriptionCard;
