import React from "react";
import { useLocation } from "react-router-dom";
import { IoMdShare, IoMdEye } from "react-icons/io";
import "./iframe.scss";
import {useEffect} from "react";
import {useNavigate, Navigate} from "react-router-dom";

const IFrame = (props) => {
  const { state } = useLocation();
  const navigate=useNavigate();

  useEffect(()=>{
    console.log(state.source, "<--------ine");
  },[state]);

  return (
    <>
      <div className="iframe">
        <iframe
          src={"https://www.elahmad.com/tv/radiant.php?id=qoran_tv"}
          width="100%"
          height="75%"
          allowFullScreen
        ></iframe>
        <div className="iframe__info">
          <div className="iframe__info__right">
            <h1>تصاویر آنلاین از مکه مکرمه</h1>
            <p>پخش زنده . شروع از ۲ساعت و ۱۲ قیقه قبل</p>
          </div>
          <div className="iframe__info__left">
            <p>
              <span>۲,۲۱۴ بازدید کل</span>
            </p>
            <p>
              <IoMdShare />
            </p>
          </div>
        </div>
        <hr />
        <div className="iframe__description">
          <p>پخش تصاویر آنلاین مکه کرمه </p>
        </div>
      </div>
    </>
  );
};

export default IFrame;
