import axios from "axios";
import React, { useEffect, useState } from "react";
import { toFarsiNumber } from "../../utils/utilityFunctions";
import { FaReply } from "react-icons/fa";

import "./commentCard.scss";

const CommentCard = ({
  reviewText,
  reviewTitle,
  rating,
  firstName,
  userName,
  setNewCommentBoxStatus,
  setCommentReplyTo,
}) => {
  const [courseComment, setCourseComment] = useState({});
  const [loading, setLoading] = useState(true);

  function commentReplyHandler(e) {
    setNewCommentBoxStatus(true);
    setCommentReplyTo(firstName); // Pass the entire userName object
  }

  return (
    <div className="commentCard">
      <>
        <div className="commentCard__rightCol">
          <div className="commentCard__rightCol__userLogo">
            <img
              className="commentCard__rightCol__userLogo--img"
              src="https://secure.gravatar.com/avatar/4a30daffd82b33210ca4a62911cfb480?s=96&d=mm&r=g"
              alt="UserLogo"
            />
          </div>
          {/* <div className="commentCard__rightCol--userFlag">
            <span>دانشجو</span>
          </div> */}
        </div>
        <div className="commentCard__leftCol">
          <div className="commentCard__leftCol__head">
            <div
              className="commentCard__leftCol__head--btn"
              // onClick={commentReplyHandler}
            >
              {/* <FaReply /> */}
            </div>
            <div className="commentCard__leftCol__head__detail">
              <span className="commentCard__leftCol__head__detail--userName">
                {`${userName.firstName}    ${userName.lastName}`}
              </span>
              <span className="commentCard__leftCol__head__detail--date">
                {toFarsiNumber(rating)}
                {/* {toFarsiNumber(1402)}/ */}
                {/* {toFarsiNumber(30)} */}
              </span>
            </div>
          </div>
          <div className="commentCard__leftCol__text">
            <h1>{reviewTitle}</h1>
            <p>{reviewText}</p>
          </div>
        </div>
      </>
    </div>
  );
};

export default CommentCard;
