import React, { useEffect, useState } from "react";
import axios from "axios";
import { BsPeopleFill } from "react-icons/bs";
import { AiTwotoneStar } from "react-icons/ai";
import { FaRegCopy } from "react-icons/fa";
import { toFarsiNumber } from "../../utils/utilityFunctions";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import "./madahSidebar.scss";
import { IoMdFlag } from "react-icons/io";

const MadahSidebar = ({ madahId }) => {
  const [madahInfo, setMadahInfo] = useState();

  useEffect(() => {
    axios
      .get(`/api/madah/${madahId}`)
      .then((response) => {
        setMadahInfo(response.data);
      })
      .catch((error) => {
        console.error("Error fetching course information:", error);
      });
  }, [madahId]);

  const decodedCover = decodeURIComponent(madahInfo?.profilePic || "");
  const coverfile = `/api/${decodedCover}`;

  return (
    <div className="madahSidebar">
      {/* course teacher info */}
      <div className="madahSidebar__box">
        <img
          src={coverfile}
          alt="course teacher"
          className="madahSidebar__box__teacherImage"
        />

        {/* <div className="madahSidebar__box__teacherIcon">
          <AccountCircleIcon style={{ fontSize: "5rem" }} />
        </div> */}

        {/* student count and course rate and progress */}
        {/* <div className="madahSidebar__box">
          <div className="madahSidebar__box__info">
            <div className="madahSidebar__box__info__student">
              <div className="madahSidebar__box__info__student__text">
                <span>{toFarsiNumber(courseInfo?.students || 0)}</span>
                <p>تعداد پلی </p>
              </div>
              <BsPeopleFill className="madahSidebar__box__info__student--icon" />
            </div>
            <div className="madahSidebar__box__info__rate">
              <div className="madahSidebar__box__info__rate__text">
                <span>{toFarsiNumber(courseInfo?.ratingQuantity || 0)}</span>
                <p>رضایت</p>
              </div>
              <AiTwotoneStar className="madahSidebar__box__info__rate--icon" />
            </div>
          </div>
        </div> */}
        {/* <Link to={`/teacher/${courseInfo?.instructor?._id}`}></Link> */}
        <span className="madahSidebar__box__teacherTitle">
          {madahInfo?.name || ""}
        </span>
      </div>
      {/* course link */}
      <div className="madahSidebar__box">
        <div className="madahSidebar__box__linkHeader">
          <span>بیوگرافی</span>
        </div>
        <div className="madahSidebar__box__linkBox">
          <span> {madahInfo?.bio || ""}</span>
          {/* <FaRegCopy className="madahSidebar__box__linkBox--icon" /> */}
        </div>
        <p className="madahSidebar__box__report">
          گزارش تخلف
          <IoMdFlag size={24} style={{ marginTop: "-0.3rem" }} />
        </p>
      </div>
    </div>
  );
};

export default MadahSidebar;
