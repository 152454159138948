import React, { useEffect, useState } from "react";
import "./documentary.scss";
import DocumentaryCard from "../../Components/documentaryCard/DocumentaryCard";
import axios from "axios";
import useFetch from "../../request/useFetch";

const Documentary = () => {
  const [documentaryData, setDocumentaryData] = useState([]);

  useEffect(() => {
    axios.get("/api/videos").then((response) => {
      setDocumentaryData(response.data);

      const documentaryVideos = response.data.filter(
        (video) => video.subset === "documentary"
      );
      setDocumentaryData(documentaryVideos);
    });
  }, []);

  const { data, error, loading } = useFetch("/api/videos");
  useEffect(() => {
    setDocumentaryData(data.filter((video) => video.subset === "documentary"));
  }, [data]);

  return (
    <div className="documentary">
      {documentaryData.map((documentary) => {
        return <DocumentaryCard key={documentary._id} {...documentary} />;
      })}
    </div>
  );
};

export default Documentary;
