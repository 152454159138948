import React, { useEffect, useState } from "react";
import axios from "axios";

import "./madah.scss";

import CourseDescription from "../../Components/courseComponents/courseDescription/CourseDescription";
import MadahContent from "../../Components/madahContent/MadahContent";
import { toFarsiNumber } from "../../utils/utilityFunctions";
import { useParams } from "react-router-dom";
import MadahSidebar from "../../Components/madahSidebar/MadahSidebar";
import MadahDescription from "../../Components/madahDescription/MadahDescription";

const Madah = () => {
  const [madahData, setMadahData] = useState();
  const { id } = useParams();

  useEffect(() => {
    axios
      .get(`/api/madah/${id}`)
      .then((response) => {
        setMadahData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching course data:", error);
      });
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const lastUpdate = `${toFarsiNumber(1402)}/ ${toFarsiNumber(
    6
  )} / ${toFarsiNumber(2)} `;

  const courseDuration = ` ساعت ${toFarsiNumber(54)}`;

  const decodedCover = decodeURIComponent(madahData?.profilePic || "");
  const coverfile = `/api/${decodedCover}`;

  return (
    <div className="post-single">
      <div className="madah">
        <div className="madah__header">
          {/* <div className="madah__header__cover">
            <img src={coverfile} alt="madah cover" />
          </div> */}
          {/* <div className="madah__header__detail">
            <div className="madah__header__detail__text">
              <h2 className="madah__header__detail__text__title">
                {madahData?.name || "Loading..."}
              </h2>
              <p className="madah__header__detail__text__description">
                {madahData?.bio || "Loading..."}
              </p>
            </div>
          </div> */}
        </div>
        <div className="madah__container">
          <MadahSidebar madahId={id} />
          <div className="course__container__main">
            <div className="course__container__main__info"></div>
            <MadahDescription description={madahData?.description || ""} />

            {/* Map through occasions array */}
            {madahData?.occasions?.map((occasion, index) => (
              <div key={index}>
                {/* Render components for each occasion */}
                <p>Date: {occasion.date}</p>
                <p>Title: {occasion.title}</p>
                {/* Add more components based on your occasion structure */}
              </div>
            ))}
            <MadahContent occasion={madahData?.occasion || []} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Madah;
